import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import paymentService from 'services/payment.service';
import { useSnackbar } from 'notistack';
// assets
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import useAuth from 'hooks/useAuth';
import TrialPackages from './TrialPackages';
import ActivePackages from './ActivePackages';

export default function Packages() {
    const navigate = useNavigate();
    const [subscriptionData, setSubscriptionData] = useState(null);
    const [stripeStatus, setStripeStatus] = useState('');

    const checkIncompletePayment = async () => {
        const { data } = await paymentService.checkIncompletePayment();
        setStripeStatus(data?.stripe_status);
    };

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (subscriptionData) {
            enqueueSnackbar('Subscription Changed', { variant: 'success', autoHideDuration: 2500 });
            navigate(
                {
                    pathname: subscriptionData.redirectTo
                },
                { state: subscriptionData }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscriptionData, navigate]);

    useEffect(() => {
        checkIncompletePayment();
    }, []);

    return (
        <>
            <Typography variant="h3" mt={4} mb={2}>
                Plan
            </Typography>
            {stripeStatus === 'trialing' && <TrialPackages stripeStatus="trialing" />}
            {stripeStatus === 'active' && <ActivePackages stripeStatus="active" />}
            {stripeStatus === 'inactive' && <ActivePackages stripeStatus="inactive" />}
            {stripeStatus === 'none' && <ActivePackages stripeStatus="none" />}
            {stripeStatus === 'deleted' && <ActivePackages stripeStatus="deleted" />}
            {stripeStatus === 'canceled' && <ActivePackages stripeStatus="canceled" />}
            {stripeStatus === 'incomplete' && <ActivePackages stripeStatus="incomplete" />}
            {stripeStatus === 'incomplete_expired' && <ActivePackages stripeStatus="incomplete_expired" />}
            {stripeStatus === 'past_due' && <ActivePackages stripeStatus="past_due" />}
        </>
    );
}
