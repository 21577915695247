import React from 'react';
import { MenuItem, Select, Grid, Typography, TextField, Button } from '@mui/material';
import PropTypes from 'prop-types';

const BooleanChoice = ({ formik, allQuesData, choiceMap, index, ruleIndex, conditionIndex, selectedOption }) => {
    // console.log(
    //     formik.values.restrictions,
    //     formik.values.restrictions[[index]],
    //     Object.values(formik.values.restrictions[[index]])[[ruleIndex]],
    //     Object.values(formik.values.restrictions[[index]])[[ruleIndex]].cond,
    //     index,
    //     ruleIndex
    // );
    const currentQuesInfo = allQuesData.find(
        (ques) => ques.id === formik.values.restrictions[index]?.allRules[ruleIndex]?.cond[[conditionIndex]]?.ques
    );
    const choices = [
        { name: currentQuesInfo.meta.yesLabel, value: '1' },
        { name: currentQuesInfo.meta.noLabel, value: '0' }
    ];
    return (
        <>
            <Grid item xs={3}>
                <Select
                    value={formik.values.restrictions[index].allRules[ruleIndex].cond[conditionIndex].assignmentOp}
                    onChange={formik.handleChange}
                    name={`restrictions[${[index]}].allRules[${[ruleIndex]}].cond[${[conditionIndex]}].assignmentOp`}
                    fullWidth
                    size="small"
                    SelectDisplayProps={{ 'data-testid': 'conditional-operator-select' }}
                >
                    <MenuItem value="equal"> is </MenuItem>
                    <MenuItem value="not equal"> is not </MenuItem>
                </Select>
            </Grid>
            <Grid item xs={3}>
                <Select
                    size="small"
                    value={formik.values.restrictions[index].allRules[ruleIndex].cond[conditionIndex].val}
                    onChange={formik.handleChange}
                    name={`restrictions[${[index]}].allRules[${[ruleIndex]}].cond[${[conditionIndex]}].val`}
                    fullWidth
                    SelectDisplayProps={{ 'data-testid': 'condition-question-select' }}
                >
                    {choices.map((choice, index) => (
                        <MenuItem value={choice.value} key={index}>
                            {choice.name}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
        </>
    );
};
BooleanChoice.propTypes = {
    formik: PropTypes.object,
    allQuesData: PropTypes.array,
    choiceMap: PropTypes.object,
    index: PropTypes.number,
    ruleIndex: PropTypes.number,
    conditionIndex: PropTypes.number,
    selectedOption: PropTypes.number
};

export default BooleanChoice;
