import { TextField, Tooltip } from '@mui/material';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Emitter from 'utils/emitter';

const AnswerVariable = ({ formik }) => {
    // Default Value
    const [answerVariable, setAnswerVariable] = useState('');
    const [activelyEditing, setActivelyEditing] = useState(false);

    const valueSetter = (formik) => {
        if (formik && formik.values && isUndefined(formik.values?.meta?.answer_variable)) {
            // add
            formik.setFieldValue('meta.answer_variable', answerVariable);
        } else if (!isUndefined(formik.values?.meta?.answer_variable)) {
            // edit
            setAnswerVariable(formik.values?.meta?.answer_variable);
        }
    };

    Emitter.on('FORMIK_RENDERED', (formik) => valueSetter(formik));

    const handleChange = (event) => {
        formik.setFieldValue('meta.answer_variable', event.target.value);
        setAnswerVariable(event.target.value);
    };

    const handleFocus = () => {
        setActivelyEditing(true);
    };

    const handleBlur = () => {
        setActivelyEditing(false);
    };

    return (
        <Tooltip title="Warning: The variable name cannot be modified after saving the question" placement="top">
            <TextField
                onChange={handleChange}
                name="meta.answer_variable"
                value={answerVariable || ''}
                id="standard-basic"
                label="Answer Variable"
                fullWidth
                InputLabelProps={{
                    shrink: true
                }}
                sx={{ mt: 1 }}
                variant="outlined"
                onBlur={handleBlur}
                onFocus={handleFocus}
                disabled={
                    typeof formik.values?.id === 'number' &&
                    formik.values?.meta?.answer_variable !== '' &&
                    formik.values?.meta?.answer_variable !== null &&
                    !activelyEditing
                }
            />
        </Tooltip>
    );
};

AnswerVariable.propTypes = {
    formik: PropTypes.object
};

export default AnswerVariable;
