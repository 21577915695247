/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { animated, useSpring } from '@react-spring/web';
import SaveSharpIcon from '@mui/icons-material/SaveSharp';
import Add from '@mui/icons-material/Add';
// material-ui
import {
    Avatar,
    Button,
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    ListSubheader,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    Switch,
    TextField,
    Typography,
    Tooltip,
    IconButton,
    LinearProgress
} from '@mui/material';
// project imports
import SurveyService from 'services/survey.service';
import { DateTimePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
// third-party
import { useFormik } from 'formik';
import * as yup from 'yup';
import { concat, isNull, cloneDeep, initial } from 'lodash';
import { useSnackbar } from 'notistack';
import { Box } from '@mui/system';
import RichTextEditor from 'views/rich-text-editor/ReactQuill';
import useAuth from 'hooks/useAuth';
import { IconPencil, IconUpload } from '@tabler/icons';
import { response } from 'msw';
import GeneralService from 'services/general.service';
import Loader from 'views/components/Loader';
import { Upload, DeleteOutline } from '@mui/icons-material';
import dayjs from 'dayjs';
import MiscService from 'services/misc.service';
import DateTimeFields from './DateTimeFields';
import ProfileService from 'services/profile.service';
import RestrictionSection from './RestrictionSection';
import CoverImageUpload from './CoverImageUpload';
import RequiredLabel from 'components/RequiredLabel';

// ==============================|| Style ||============================== //
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    actionsContainer: {
        marginBottom: theme.spacing(2)
    },
    resetContainer: {
        padding: theme.spacing(3)
    }
}));

// ==============================|| Main Template ||============================== //
const Form = ({
    initialData,
    surveyAction,
    activeUser = 0,
    activeUsersId = 0,
    activeUserOneMonthAgo = 0,
    activeUserTwoWeekAgo = 0,
    mode,
    isSaveButtonLoading
}) => {
    const classes = useStyles();
    const { user } = useAuth();
    const [minQouta, setMinQouta] = React.useState(
        typeof initialData?.valid_answers_count === 'undefined' || initialData?.valid_answers_count === 0
            ? 1
            : initialData?.valid_answers_count
    );

    const [selectedTimezone, setSelectedTimezone] = React.useState(
        initialData?.meta?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
    );

    // Formik - form
    const validationSchema = yup.object({
        survey_title: yup.string().required('Survey Name is required').max(100, 'Survey Name must be at most 100 characters'),
        survey_description: yup.string().nullable().max(400, 'Description must be at most 400 characters'),
        quota: yup
            .number('Quota must be a Number')
            .required('Quota is required')
            .min(minQouta, `Must be greater than ${minQouta - 1}`)
            .max(
                user?.meta?.response_remained,
                `You have ${user?.meta?.response_remained ?? 0} response(s) left. ${
                    user?.meta?.response_remained ? 'Please upgrade your plan. ' : ''
                }`
            )
            .typeError('Quota must be a Number'),
        survey_type: yup.string().required('Survey Type is required')
        // survey_status: yup.string().required('Survey Status is required'),
        // start_date: yup.date().when([], {
        //     is: () => mode === 'add',
        //     then: yup
        //         .date()
        //         .transform((v) => (v instanceof Date && !Number.isNaN(v) ? v : null))
        //         .required('Start Date field is Required')
        //         .typeError('Start Date field is Required and must be in date format')
        //         .min(
        //             new Date(
        //                 dayjs(new Date().toLocaleString('en-US', { timeZone: selectedTimezone }))
        //                     .tz(selectedTimezone)
        //                     .valueOf()
        //             ),
        //             'Start date cannot precede the current time.'
        //         ),
        //     otherwise: yup.date().notRequired()
        // })
        // end_date: yup
        //     .date()
        //     .transform((v) => (v instanceof Date && !Number.isNaN(v) ? v : null))
        //     .required('End Date field is Required')
        //     .when('start_date', (startDate, schema) => {
        //         if (startDate) {
        //             const endDate = new Date(startDate).getTime();

        //             return schema.min(new Date(endDate), 'End date has to be after than start date');
        //         }

        //         return schema;
        //     })
        //     .typeError('End Date field is Required and must be in date format')
    });
    const [surveyType, setSurveyType] = React.useState({});
    // Initial Survey Statuses (Live status will be added on only edit mode)
    const [surveyStatus, setSurveyStatus] = React.useState(['Development', 'Staging']);
    const [coreRestrictions, setCoreRestrictions] = React.useState(['age', 'gender', 'country']);
    const [survey, setSurvey] = React.useState(initialData);
    const [timezones, setTimezones] = React.useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    // The Restrictions modified value
    const [restrictionValues, setRestrictionValues] = React.useState([]);
    // The Restrictions that are selected
    const [selectedRestrictions, setSelectedRestrictions] = React.useState([]);
    const [availableActiveUser, setAvailableActiveUser] = React.useState(activeUser);
    const [availableActiveUsersId, setAvailableActiveUsersId] = React.useState(activeUsersId);
    const [availableActiveUserOneMonthAgo, setAvailableActiveUserOneMonthAgo] = React.useState(activeUserOneMonthAgo);
    const [availableActiveUserTwoWeekAgo, setAvailableActiveUserTwoWeekAgo] = React.useState(activeUserTwoWeekAgo);
    const [endOpen, setEndOpen] = React.useState(false);
    const [mainCoreRestrictions, setMainCoreRestrictions] = useState([
        {
            key: 'age',
            values: [],
            label: 'Age'
        },
        {
            key: 'gender',
            values: [{ choice: 'Male' }, { choice: 'Female' }],
            label: 'Gender'
        },
        {
            key: 'country',
            values: [],
            label: 'Country'
        }
    ]);

    const formik = useFormik({
        validationSchema,
        initialValues: survey,
        enableReinitialize: true,
        onSubmit: async (values) => {
            // eslint-disable-next-line no-use-before-define
            if (selectedRestrictions.length === 0) {
                values.meta = {
                    ...values.meta,
                    restrictions: selectedRestrictions,
                    // eslint-disable-next-line no-use-before-define
                    allRestrictionValues
                };
            } else {
                values.meta = {
                    ...values.meta,
                    restrictions: selectedRestrictions,
                    // eslint-disable-next-line no-use-before-define
                    allRestrictionValues,
                    availableActiveUsersId
                };
            }

            surveyAction(values);
        }
    });

    const [style, set] = useSpring(() => ({
        color: '#000000'
    }));

    const handleSplash = () => {
        set({
            color: '#FF0000',
            letterSpacing: 4,
            onRest: () => {
                set({
                    letterSpacing: 0,
                    color: '#000000'
                });
            }
        });
    };

    const [addRestriction, setAddRestriction] = React.useState(false);
    // Main Restrictions by merging profile survey and key
    const [allRestrictionValues, setAllRestrictionValues] = React.useState(formik.values?.meta?.allRestrictionValues ?? []);
    const [restrictionValuesChecker, setRestrictionValuesChecker] = React.useState([]);

    const [restrictions, setRestrictions] = React.useState(formik.values?.meta?.restrictions ?? []);
    // EAV and Core Resriction Information
    const [restrictionsInformation, setRestrictionsInformation] = React.useState([]);
    // MobileDatepicker open state
    const [isOpen, setIsOpen] = React.useState(false);
    const [loaderOpen, setLoaderOpen] = React.useState(false);
    const [templatePreviewImage, setTemplatePreviewImage] = React.useState('');
    const [checkedRestrictionKeys, setCheckedRestrictionKeys] = React.useState([]);

    const selectedRestrictionRef = useRef();
    selectedRestrictionRef.current = allRestrictionValues;
    const { enqueueSnackbar } = useSnackbar();

    const [logoPreview, setLogoPreview] = useState(user.meta?.logo || '');

    const fetchData = async () => {
        const { data } = await SurveyService.getSurveyType();
        setSurveyType(data);
    };

    const fetchRestrictionData = async (initialData) => {
        if (initialData.meta?.restrictions) {
            setAddRestriction(initialData?.meta?.allRestrictionValues.length > 0);
            setSelectedRestrictions(
                initialData.meta.restrictions.map((restriction) => ({
                    id: restriction.id,
                    name: restriction.restrictionType,
                    ...restriction
                }))
            );
            setRestrictionValues(initialData.meta.restrictions);
        }
    };

    const getRestrictionValues = async () => {
        // Set Restriction Information
        const restrictionData = await SurveyService.getRestrictions();
        const restrictionsInformation = concat(restrictionData.data.core_restrictions, restrictionData.data.eav_restrictions);
        setRestrictionsInformation(restrictionsInformation);

        // Main Restrictions by merging profile question choices and key
        const { data: coreRestricitonData } = await SurveyService.getCoreRestrictions();
        setRestrictions(coreRestricitonData);
        setMainCoreRestrictions(coreRestricitonData);
    };

    const handleAddRestriction = () => {
        setAddRestriction((prev) => !prev);
    };

    const handleLogoUploadClick = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        if (file.size / 1024 / 1024 > 5) {
            enqueueSnackbar('Image must be less than 5MB !', { variant: 'error', autoHideDuration: 3000 });
        } else {
            setLoaderOpen(true);
            reader.onloadend = async () => {
                const fd = new FormData();
                fd.append('image', file);

                try {
                    const { data: imageLink } = await GeneralService.storeImage(fd, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    // Update user profile with new logo
                    await ProfileService.update({
                        meta: {
                            ...user.meta,
                            logo: imageLink
                        }
                    });

                    // Update local user state if needed
                    if (user?.setUser) {
                        user.setUser({
                            ...user,
                            meta: {
                                ...user.meta,
                                logo: imageLink
                            }
                        });
                    }

                    // Update logo preview
                    setLogoPreview(imageLink);

                    enqueueSnackbar('Logo updated successfully!', { variant: 'success', autoHideDuration: 5000 });
                } catch (error) {
                    enqueueSnackbar('Failed to update logo. Please try again.', { variant: 'error', autoHideDuration: 5000 });
                } finally {
                    setLoaderOpen(false);
                }
            };

            reader.readAsDataURL(file);
        }
    };

    const getTimezones = async () => {
        const { data } = await MiscService.getTimezones();
        setTimezones(data.timezones);
    };

    const handleRestrictedUserData = async (currentRestricitonState) => {
        const { data: respondentData } = await SurveyService.filterUserRestrictions(currentRestricitonState);
        setAvailableActiveUser(respondentData?.active_respondent);
        setAvailableActiveUsersId(respondentData?.active_respondents_id);
        setAvailableActiveUserOneMonthAgo(respondentData?.prev_one_month_respondent);
        setAvailableActiveUserTwoWeekAgo(respondentData?.prev_two_week_respondent);
        handleSplash();
    };

    React.useEffect(() => {
        if (isNull(initialData.meta)) {
            // TODO : keep this in some helper constants
            initialData.meta = {
                save_as_template: false,
                public_survey: true,
                show_in_listing: true,
                anon_survey: false,
                is_prized_survey: false,
                show_thankyou: false
            };
        }
        setSurvey(initialData);
    }, [initialData]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        fetchRestrictionData(initialData);
    }, [initialData.meta]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        fetchData();
        getRestrictionValues();
        getTimezones();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (allRestrictionValues?.length > 0 && restrictions?.length > 0) {
            let currentRestricitonState = cloneDeep(allRestrictionValues);

            currentRestricitonState = currentRestricitonState.map((restrictionValue) => {
                restrictionValue.values = restrictionValue.values.map((val) => val?.id ?? val?.choice ?? val);
                return restrictionValue;
            });
            handleRestrictedUserData(currentRestricitonState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restrictions]);

    const handleNestedAccordionCheckbox = async (key, selectedValue) => {
        let currentRestricitonState = '';

        setAllRestrictionValues((prevValue) => {
            const mutableIndex = prevValue.findIndex((d) => d.key === key);

            if (mutableIndex >= 0) {
                // if there is duplicate objects
                const foundDuplicateChoice = selectedValue.find((nnn, index) =>
                    selectedValue.find((x, ind) => x.choice === nnn.choice && index !== ind)
                );
                if (foundDuplicateChoice) {
                    selectedValue = selectedValue.filter((selected) => selected.choice !== foundDuplicateChoice.choice);
                }

                // if (prevValue[mutableIndex].values.find((val) => val.choice === selectedValue.choice)) {
                prevValue[mutableIndex].values = selectedValue;
                // } else {
                // prevValue[mutableIndex].values = [...prevValue[mutableIndex].values, selectedValue];
                // }
            } else {
                // eslint-disable-next-line no-lonely-if
                if (prevValue?.length > 0) {
                    prevValue.push({ key, values: selectedValue });
                } else {
                    prevValue = [{ key, values: selectedValue }];
                }
            }
            currentRestricitonState = cloneDeep(prevValue);

            return prevValue;
        });

        // WIP: RAFID2
        // const { data: respondentData } = await SurveyService.filterUserRestrictions(selectedRestrictionRef.current);
        currentRestricitonState = currentRestricitonState.map((restrictionValue) => {
            restrictionValue.values = restrictionValue.values.map((val) => val?.id ?? val?.choice ?? val);
            return restrictionValue;
        });
        handleRestrictedUserData(currentRestricitonState);
    };

    const testChange = (event, values) => {
        const key = event.target.name;
        handleNestedAccordionCheckbox(key, event.target.value);
    };
    const [expanded, setExpanded] = React.useState(false);

    React.useEffect(() => {
        if (mode === 'add') {
            const timezoneCurrentDateTime = new Date(
                dayjs(new Date().toLocaleString('en-US', { timeZone: selectedTimezone }))
                    .add(2, 'minute')
                    .tz(selectedTimezone)
                    .valueOf()
            );
            formik.setFieldValue('start_date', timezoneCurrentDateTime);
        }

        formik.setFieldValue('meta.timezone', selectedTimezone);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTimezone]);

    // Additional Changes for Edit Mode
    React.useEffect(() => {
        if (mode === 'edit') {
            setSurveyStatus((prevValue) => [...prevValue, 'Live']);
        }
    }, [mode]);

    return (
        <form className={classes.root} autoComplete="off" onSubmit={formik.handleSubmit} style={{ marginTop: '20px' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <TextField
                        fullWidth
                        placeholder="Name of your survey"
                        InputLabelProps={{ shrink: true }}
                        label={
                            <RequiredLabel
                                text={`Survey Name ${
                                    formik.values.survey_title?.length ? `(${formik.values.survey_title?.length || 0}/100)` : ''
                                }`}
                            />
                        }
                        value={formik.values.survey_title}
                        onChange={formik.handleChange}
                        name="survey_title"
                        error={formik.touched.survey_title && Boolean(formik.errors.survey_title)}
                        helperText={formik.touched.survey_title && formik.errors.survey_title}
                        inputProps={{ maxLength: 100 }}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        multiline
                        rows={4}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        placeholder="Description of your survey"
                        label={`Description ${
                            formik.values.survey_description?.length ? `(${formik.values.survey_description?.length || 0}/400)` : ''
                        }`}
                        value={formik.values.survey_description ? formik.values.survey_description : ''}
                        onChange={formik.handleChange}
                        name="survey_description"
                        error={formik.touched.survey_description && Boolean(formik.errors.survey_description)}
                        helperText={formik.touched.survey_description && formik.errors.survey_description}
                        inputProps={{ maxLength: 400 }}
                    />
                </Grid>
                {/* <DateTimeFields
                    formik={formik}
                    timezones={timezones}
                    selectedTimezone={selectedTimezone}
                    setSelectedTimezone={setSelectedTimezone}
                    mode={mode}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    endOpen={endOpen}
                    setEndOpen={setEndOpen}
                /> */}
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label={<RequiredLabel text="Number of Respondents Quota" />}
                        type="number"
                        inputProps={{ min: '0' }}
                        value={formik.values.quota}
                        onChange={formik.handleChange}
                        name="quota"
                        error={formik.touched.quota && Boolean(formik.errors.quota)}
                        helperText={formik.touched.quota && formik.errors.quota}
                        disabled={mode === 'edit' && survey.survey_prize > 0}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        id="outlined-select-experience"
                        select
                        fullWidth
                        label={<RequiredLabel text="Survey Type" />}
                        value={formik.values.survey_type}
                        onChange={formik.handleChange}
                        name="survey_type"
                        error={formik.touched.survey_type && Boolean(formik.errors.survey_type)}
                        helperText={formik.touched.survey_type && formik.errors.survey_type}
                    >
                        <ListSubheader>Survey Type</ListSubheader>
                        {Object.values(surveyType).map((value, index) => (
                            <MenuItem key={index} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                {/* {user?.user_role.includes('Super Admin') && ( */}
                <Grid item xs={12} md={6}>
                    <CoverImageUpload formik={formik} />
                </Grid>
                <RestrictionSection
                    addRestriction={addRestriction}
                    handleAddRestriction={handleAddRestriction}
                    style={style}
                    availableActiveUser={availableActiveUser}
                    availableActiveUserOneMonthAgo={availableActiveUserOneMonthAgo}
                    availableActiveUserTwoWeekAgo={availableActiveUserTwoWeekAgo}
                    restrictions={restrictions}
                    coreRestrictions={coreRestrictions}
                    selectedRestrictionRef={selectedRestrictionRef}
                    testChange={testChange}
                    disabled={formik.values.meta.anon_survey}
                />

                <Grid item xs={12} md={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={Boolean(formik.values?.meta?.show_redirection)}
                                onChange={(event) => {
                                    formik.setFieldValue('meta.show_redirection', event.target.checked);
                                    if (!event.target.checked) {
                                        formik.setFieldValue('meta.redirection_url', '');
                                    }
                                }}
                                name="meta.show_redirection"
                            />
                        }
                        label="Include a redirection URL after survey completion"
                    />

                    {formik.values?.meta?.show_redirection && (
                        <TextField
                            fullWidth
                            label="Redirect URL after survey completion"
                            type="text"
                            value={formik.values.meta.redirection_url}
                            onChange={formik.handleChange}
                            name="meta.redirection_url"
                            error={formik.touched.redirection_url && Boolean(formik.errors.redirection_url)}
                            helperText={formik.touched.redirection_url && formik.errors.redirection_url}
                        />
                    )}
                </Grid>

                <Grid item xs={12} md={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={Boolean(formik.values?.meta?.show_thankyou)}
                                onChange={(event) => {
                                    formik.setFieldValue('meta.show_thankyou', event.target.checked);
                                    if (!event.target.checked) {
                                        formik.setFieldValue('meta.thankyou_text', '');
                                    }
                                }}
                                name="meta.show_thankyou"
                            />
                        }
                        label="Include a Thank you Message after completing the survey"
                    />

                    {formik.values?.meta?.show_thankyou ? (
                        <RichTextEditor name="meta.thankyou_text" formik={formik} value={formik.values?.meta?.thankyou_text} />
                    ) : null}
                </Grid>

                <Grid item xs={12} md={12}>
                    <Box sx={{ alignItems: 'center', gap: 2 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={Boolean(formik.values?.meta?.logo)}
                                    value={formik.values?.meta?.logo}
                                    onChange={formik.handleChange}
                                    name="meta.logo"
                                />
                            }
                            label="Include Logo"
                        />
                        {logoPreview && formik.values?.meta?.logo && (
                            <div style={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: '10px', marginTop: '10px' }}>
                                <Avatar
                                    src={logoPreview}
                                    alt="Profile"
                                    sx={{ width: 56, height: 56, backgroundColor: 'transparent', border: '1px solid #ddd' }}
                                />
                                <IconButton component="label" size="small" p={0}>
                                    <IconPencil />
                                    <input type="file" hidden onChange={handleLogoUploadClick} />
                                </IconButton>
                            </div>
                        )}
                        {!logoPreview && formik.values?.meta?.logo && (
                            <Button variant="outlined" component="label" fullWidth>
                                Upload Logo
                                <input type="file" hidden onChange={handleLogoUploadClick} />
                            </Button>
                        )}
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={Boolean(formik.values.show_in_listing)}
                                        value={formik.values.show_in_listing}
                                        onChange={(event, value) => {
                                            const newValue = value ? 1 : 0;
                                            formik.setFieldValue('show_in_listing', newValue);
                                        }}
                                        name="show_in_listing"
                                    />
                                }
                                label="Show in listing"
                            />
                        </Grid>
                        {user?.user_role.includes('Super Admin') && (
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={formik.values.meta.save_as_template}
                                            value={formik.values.meta.save_as_template}
                                            onChange={formik.handleChange}
                                            name="meta.save_as_template"
                                        />
                                    }
                                    label="Save as template"
                                />
                            </Grid>
                        )}
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formik.values.meta.public_survey}
                                        value={formik.values.meta.public_survey}
                                        onChange={(event) => {
                                            formik.setFieldValue('meta.anon_survey', !event.currentTarget.checked);
                                            formik.setFieldValue('meta.public_survey', event.currentTarget.checked);
                                        }}
                                        name="meta.public_survey"
                                    />
                                }
                                label="Public Survey"
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formik.values.meta.anon_survey}
                                        value={formik.values.meta.anon_survey}
                                        onChange={(event) => {
                                            formik.setFieldValue('meta.public_survey', !event.currentTarget.checked);
                                            formik.setFieldValue('meta.anon_survey', event.currentTarget.checked);
                                        }}
                                        name="meta.anon_survey"
                                    />
                                }
                                label="Anonymous Survey"
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={Boolean(formik.values?.meta?.is_prized_survey)}
                                        onChange={(event) => {
                                            formik.setFieldValue('meta.is_prized_survey', event.target.checked);
                                            if (!event.target.checked) {
                                                formik.setFieldValue('survey_prize', 0);
                                            }
                                        }}
                                        name="meta.is_prized_survey"
                                    />
                                }
                                label="Prize Survey"
                            />
                        </Grid>
                        {formik.values?.meta?.is_prized_survey && (
                            <Grid item xs={12} md={12} mt={1}>
                                <TextField
                                    fullWidth
                                    label="Survey Prize"
                                    type="number"
                                    inputProps={{ min: '0' }}
                                    value={formik.values.survey_prize}
                                    onChange={formik.handleChange}
                                    name="survey_prize"
                                    error={formik.touched.survey_prize && Boolean(formik.errors.survey_prize)}
                                    helperText={formik.touched.survey_prize && formik.errors.survey_prize}
                                />
                            </Grid>
                        )}
                    </Grid>

                    <Grid item xs={12} mt={3}>
                        <LoadingButton
                            startIcon={<SaveSharpIcon />}
                            variant="contained"
                            type="submit"
                            fullWidth
                            size="large"
                            disabled={mode === 'edit' && survey.survey_prize > 0 && !survey?.meta?.prized_survey_paid}
                            loading={isSaveButtonLoading}
                            loadingPosition="start"
                        >
                            Save
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};
Form.propTypes = {
    initialData: PropTypes.object,
    surveyAction: PropTypes.func,
    activeUser: PropTypes.number,
    activeUsersId: PropTypes.number,
    activeUserOneMonthAgo: PropTypes.number,
    activeUserTwoWeekAgo: PropTypes.number,
    mode: PropTypes.string,
    isSaveButtonLoading: PropTypes.bool
};
export default Form;
