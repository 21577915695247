import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Box, Typography, Grid, Button, Chip, List, ListItem, ListItemIcon, ListItemText, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import HelpIcon from '@mui/icons-material/Help';
import {
    AccessTime as ClockIcon,
    Group as UsersIcon,
    Lock as LockIcon,
    LockOpen as UnlockIcon,
    FiberManualRecord as StatusIcon,
    Add,
    FormatListBulleted
} from '@mui/icons-material';
import dayjs from 'dayjs';
import QuestionBranching from './components/QuestionBranching';
import SurveyViewEdit from './components/SurveyViewEdit';
import { useDispatch } from 'react-redux';
import { activeMainItem } from 'store/slices/menu';
import useGlobalModal from 'hooks/useGlobalModal';
import GlobalModalHelpButton from 'views/components/GlobalModalHelpButton';
import { IconPencil } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';

const Overview = ({ survey, setValue, setSurvey }) => {
    const [open, setOpen] = useState(false);
    const { openGlobalModal } = useGlobalModal();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSidebarOpenClose = useCallback((value) => setOpen(value), []);
    const handleSurveyEditSubmit = useCallback(
        (updatedSurvey) => {
            setOpen(false);
            setSurvey(updatedSurvey);
        },
        [setSurvey]
    );

    // Memoize survey status color
    const surveyStatusColor = useMemo(() => {
        switch (survey?.survey_status) {
            case 'Live':
                return 'success';
            case 'Development':
                return 'warning';
            case 'finished':
                return 'primary';
            default:
                return 'error';
        }
    }, [survey]);

    useEffect(() => {
        dispatch(activeMainItem(['dashboard']));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAddQuestion = () => {
        setValue(1);
        navigate('?tab=create');
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid
                    item
                    xs={12}
                    sx={{
                        background: '#fff',
                        borderRadius: '10px',
                        padding: '30px',
                        height: 'calc(100vh - 100px)'
                    }}
                >
                    <Grid container spacing={3} sx={{ mb: 3 }}>
                        {/* Left Column */}
                        <Grid item xs={12} md={3}>
                            <List sx={{ padding: 0 }}>
                                <ListItem>
                                    <ListItemIcon>
                                        <StatusIcon color={surveyStatusColor} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Status"
                                        secondary={
                                            <Typography component="div">
                                                <Chip label={survey?.survey_status} color={surveyStatusColor} size="small" />
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <ClockIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Created"
                                        secondary={`${dayjs(survey?.created_at).format('MMM DD, YYYY hh:mm A')} (${
                                            survey?.meta?.timezone
                                        })`}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <UsersIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Quota Remaining"
                                        secondary={`${survey?.meta?.remaining_quota} out of ${survey?.quota}`}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <FormatListBulleted />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Survey Type"
                                        secondary={
                                            <Typography component="div">
                                                <Chip label={survey?.survey_type} size="small" />
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>
                        </Grid>

                        {/* Right Column */}
                        <Grid item xs={12} md={3}>
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        {survey?.meta?.public_survey ? <UnlockIcon color="success" /> : <LockIcon color="error" />}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Public Survey"
                                        secondary={
                                            <Typography component="div">
                                                <Chip
                                                    label={survey?.meta?.public_survey ? 'Yes' : 'No'}
                                                    color={survey?.meta?.public_survey ? 'success' : 'error'}
                                                    size="small"
                                                />
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        {survey?.meta?.anon_survey ? <UnlockIcon color="success" /> : <LockIcon color="error" />}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Anonymous Survey"
                                        secondary={
                                            <Typography component="div">
                                                <Chip
                                                    label={survey?.meta?.anon_survey ? 'Yes' : 'No'}
                                                    color={survey?.meta?.anon_survey ? 'success' : 'error'}
                                                    size="small"
                                                />
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <ClockIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Start Date"
                                        secondary={`${dayjs(survey?.start_date).format('MMM DD, YYYY hh:mm A')} (${
                                            survey?.meta?.timezone
                                        })`}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <ClockIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="End Date"
                                        secondary={`${dayjs(survey?.end_date).format('MMM DD, YYYY hh:mm A')} (${survey?.meta?.timezone})`}
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>

                    {/* Bottom Actions */}
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <Button
                                startIcon={<IconPencil />}
                                variant="contained"
                                color="primary"
                                onClick={() => handleSidebarOpenClose(true)}
                                fullWidth
                            >
                                Edit Survey
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<Add />}
                                onClick={handleAddQuestion}
                                disabled={survey?.survey_status === 'Live'}
                                fullWidth
                            >
                                Add Question
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <QuestionBranching survey={survey} variant="contained" sx={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <GlobalModalHelpButton
                                title="How branching works"
                                content="https://kb.surveyflip.com/create-survey-content/question-branching.html"
                                sx={{ width: '100%', marginTop: '5px', cursor: 'pointer' }}
                                button={<HelpIcon />}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {open && (
                <SurveyViewEdit
                    open={open}
                    handleSidebarOpenClose={handleSidebarOpenClose}
                    survey={survey}
                    setOpen={setOpen}
                    handleSurveyEditSubmit={handleSurveyEditSubmit}
                />
            )}
        </Box>
    );
};

Overview.propTypes = {
    survey: PropTypes.object.isRequired,
    setSurvey: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired
};

export default Overview;
