import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent, Typography, LinearProgress, Button, Grid } from '@mui/material';
import useAuth from 'hooks/useAuth';
import BuyMoreModal from './BuyMoreModal';

const AccountResponseInformation = () => {
    const { user } = useAuth();
    const [openModal, setOpenModal] = useState(false);
    const [responseCount, setResponseCount] = useState(100);
    const [price, setPrice] = useState(10);

    const [responseFilled, setResponseFilled] = useState(user?.meta?.response_filled ?? 0);
    const [totalResponseRemained, setTotalResponseRemained] = useState(
        (user?.meta?.response_remained ?? 0) + (user?.meta?.response_filled ?? 0)
    );

    const handleSliderChange = (event, newValue) => {
        setResponseCount(newValue);
        setPrice(Math.round((newValue / 10) * 1 * 100) / 100);
    };

    const handlePurchase = () => {
        console.log(`Purchasing ${responseCount} responses for $${price}`);
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    };

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    return (
        <Grid container spacing={4} mt={1} mb={2}>
            <Grid item xs={12} sm={6} md={3}>
                <Typography variant="body2" gutterBottom>
                    Responses quota used ({responseFilled}/{totalResponseRemained})
                </Typography>
                <LinearProgress
                    variant="determinate"
                    value={(responseFilled / totalResponseRemained) * 100}
                    sx={{ height: 10, borderRadius: 5 }}
                />
                <Button
                    variant="text"
                    color="primary"
                    onClick={handleOpenModal}
                    sx={{ p: '10px 0', '&:hover': { backgroundColor: 'transparent', textDecoration: 'underline' } }}
                >
                    Buy more responses ( $1/ per 10 response )
                </Button>

                <BuyMoreModal
                    responseCount={responseCount}
                    handleSliderChange={handleSliderChange}
                    openModal={openModal}
                    price={price}
                    handlePurchase={handlePurchase}
                    handleCloseModal={handleCloseModal}
                />
            </Grid>
        </Grid>
    );
};

export default AccountResponseInformation;
