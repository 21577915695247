/* eslint-disable react/self-closing-comp */
import {
    Badge,
    Button,
    Card,
    CardActions,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import RestrictionModal from 'views/logical-restrictions/RestrictionModal';
import Item from '../Item';
import { IconGitBranch } from '@tabler/icons';
import { useParams } from 'react-router-dom';
import useRestrictionStore from 'zustand_store/useRestrictionStore';

// ==============================|| Main Template ||============================== //

const QuestionBranching = ({ survey, variant = 'contained', sx = {} }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { surveyToken } = useParams(); // comment out because of testing purpose
    const [allQuesData, setAllQuesData] = useState([]);
    const SET_RULENGINE = useRestrictionStore((state) => state.SET_RULENGINE);

    const restrictionProps = { open, setOpen, handleOpen, handleClose, surveyToken, allQuesData, setAllQuesData, survey };

    useEffect(() => {
        SET_RULENGINE(survey.id, survey.meta?.rule_engine);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {survey && (
                <Badge
                    badgeContent={survey?.meta?.num_rule_engine ?? 0}
                    color="error"
                    sx={{
                        margin: '0 0px 15px 0',
                        width: '100%',
                        ...sx
                    }}
                >
                    <Button
                        variant={variant}
                        color="primary"
                        role="button"
                        startIcon={<IconGitBranch />}
                        onClick={async () => {
                            handleOpen();
                        }}
                        fullWidth={sx?.width === '100%'}
                    >
                        Branching and Calculation
                    </Button>
                </Badge>
            )}
            {open && <RestrictionModal {...restrictionProps} />}
        </>
    );
};

QuestionBranching.propTypes = {
    survey: PropTypes.object,
    variant: PropTypes.string,
    sx: PropTypes.object
};

export default QuestionBranching;
