import { Upload, DeleteOutline } from '@mui/icons-material';
import { Backdrop, Button, CircularProgress, Grid, Box, Typography, LinearProgress, IconButton, Tooltip } from '@mui/material';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Emitter from 'utils/emitter';
import GeneralService from 'services/general.service';

const UploadImage = ({ formik }) => {
    const [imageUrl, setImageUrl] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [loaderOpen, setLoaderOpen] = React.useState(false);

    const valueSetter = (formik) => {
        if (formik && formik.values && isEmpty(formik.values?.meta.sectionImageUrl)) {
            // add
            formik.setFieldValue('meta.sectionImageUrl', imageUrl);
        } else if (!isEmpty(formik.values?.meta.sectionImageUrl)) {
            // edit
            setImageUrl(formik.values.meta.sectionImageUrl);
        }
    };

    Emitter.on('FORMIK_RENDERED', (formik) => valueSetter(formik));

    useEffect(() => {
        if (formik?.values?.meta?.sectionImageUrl) {
            setImageUrl(formik.values.meta.sectionImageUrl);
        }
    }, [formik?.values?.meta?.sectionImageUrl]);

    const handleChange = (event) => {
        formik.setFieldValue('meta.sectionImageUrl', event.target.value);
        setImageUrl(event.target.value);
    };

    const handleLoaderToggle = () => {
        setLoaderOpen(!loaderOpen);
    };

    const handleUploadClick = (event) => {
        const file = event.target.files[0];

        if (file.size / 1024 / 1024 > 5) {
            enqueueSnackbar('Image must be less than 5MB !', { variant: 'error', autoHideDuration: 3000 });
            return;
        }

        // Immediately show the image using local URL
        const localImageUrl = URL.createObjectURL(file);
        formik.setFieldValue('meta.sectionImageUrl', localImageUrl);

        // Start upload in background
        setIsUploading(true);
        setUploadProgress(0);

        const fd = new FormData();
        fd.append('image', file);

        GeneralService.storeImage(fd, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
                const progress = (progressEvent.loaded / progressEvent.total) * 100;
                setUploadProgress(Math.round(progress));
            }
        })
            .then(({ data: imageLink }) => {
                formik.setFieldValue('meta.sectionImageUrl', imageLink);
                setImageUrl(imageLink);
                enqueueSnackbar('Image uploaded successfully!', { variant: 'success' });
            })
            .catch((error) => {
                enqueueSnackbar('Failed to upload image. Please try again.', { variant: 'error' });
                formik.setFieldValue('meta.sectionImageUrl', '');
                setImageUrl('');
            })
            .finally(() => {
                setIsUploading(false);
                URL.revokeObjectURL(localImageUrl);
            });
    };

    const handleRemoveImage = () => {
        formik.setFieldValue('meta.sectionImageUrl', '');
        setImageUrl('');
        enqueueSnackbar('Image removed successfully!', { variant: 'success' });
    };

    return (
        <Grid style={{ alignItems: 'center', width: '100%' }}>
            <Button startIcon={<Upload />} variant="outlined" component="label" fullWidth disabled={isUploading}>
                Upload an image
                <input accept="image/*" id="upload" type="file" onChange={handleUploadClick} hidden />
            </Button>

            {isUploading && (
                <Box sx={{ width: '100%', mt: 1 }}>
                    <LinearProgress variant="determinate" value={uploadProgress} sx={{ height: 4 }} />
                    <Typography variant="caption" color="textSecondary" display="block" textAlign="center">
                        Uploading: {uploadProgress}%
                    </Typography>
                </Box>
            )}

            {imageUrl && (
                <Grid style={{ margin: '20px', height: '200px', position: 'relative' }}>
                    <IconButton
                        onClick={handleRemoveImage}
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.9)'
                            }
                        }}
                    >
                        <DeleteOutline />
                    </IconButton>
                    <Box
                        sx={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            overflow: 'hidden'
                        }}
                    >
                        <img
                            src={imageUrl}
                            style={{
                                display: 'block',
                                maxWidth: '100%',
                                maxHeight: '100%',
                                minHeight: '1px'
                            }}
                            alt="uploaded"
                        />
                    </Box>
                </Grid>
            )}
        </Grid>
    );
};

UploadImage.propTypes = {
    formik: PropTypes.object
};

export default UploadImage;
