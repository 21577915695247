import { FormControlLabel, Switch, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Emitter from 'utils/emitter';

const MaxCharacters = ({ formik }) => {
    // Default Value
    const [maxCharacters, setMaxCharacters] = useState(false);

    const valueSetter = (formik) => {
        if (formik && formik.values && isUndefined(formik.values?.meta?.max_characters)) {
            // add
            formik.setFieldValue('meta.max_characters', maxCharacters);
        } else if (!isUndefined(formik.values?.meta?.max_characters)) {
            // edit
            setMaxCharacters(formik.values.meta.max_characters);
        }
    };

    Emitter.on('FORMIK_RENDERED', (formik) => valueSetter(formik));

    const handleChange = (event) => {
        if (!event.target.checked) {
            formik.setFieldValue('meta.max_characters_numbers', undefined);
        }
        formik.setFieldValue('meta.max_characters', event.target.checked);
        setMaxCharacters(event.target.checked);
    };

    const handleNumberChange = (event) => {
        formik.setFieldValue('meta.max_characters_numbers', Number(event.target.value));
    };

    return (
        <Box display="flex" flexDirection="column" sx={{ width: '100%' }}>
            <FormControlLabel
                control={<Switch checked={maxCharacters} onChange={handleChange} size="small" />}
                label="Max Characters"
                labelPlacement="start"
                sx={{ width: '100%', justifyContent: 'space-between', marginLeft: 0 }}
            />
            <br />
            {maxCharacters && formik.values?.meta && (
                <TextField
                    onChange={handleNumberChange}
                    name="meta.max_characters_numbers"
                    defaultValue={formik.values.meta.max_characters_numbers || ''}
                    id="standard-basic"
                    fullWidth
                    label="Max Characters Number"
                    placeholder="0-9999"
                    variant="outlined"
                    error={Boolean(formik.errors?.meta?.max_characters_numbers)}
                    helperText={formik.errors?.meta?.max_characters_numbers}
                    autoFocus
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            )}
        </Box>
    );
};
MaxCharacters.propTypes = {
    formik: PropTypes.object
};
export default MaxCharacters;
