import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardActions, IconButton, Typography, Grid, Button, CardMedia, Box, Radio } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisaImage from 'assets/images/icons/visa.png';
import MastercardImage from 'assets/images/icons/mastercard.png';
import { CardElement, Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AddCardForm from './AddCardForm';
import PaymentService from 'services/payment.service';
import { LoadingButton } from '@mui/lab';
import dayjs from 'dayjs';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { IconMinus, IconPlus } from '@tabler/icons';
import PropTypes from 'prop-types';

const PaymentMethods = ({ subscriptions }) => {
    const confirm = useConfirm();

    const [cards, setCards] = useState([]);
    const [cardAddingState, setCardAddingState] = useState(false);
    const [addingButtonLoadingState, setAddingButtonLoadingState] = useState(false);

    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState(null);
    const [defaultPayment, setDefaultPayment] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const options = {
        clientSecret
        // appearance
    };

    const fetchPaymentIntent = async () => {
        const { data } = await PaymentService.createPaymentIntentForUser();
        setStripePromise(loadStripe(data.stripe_key));
        setClientSecret(data.intent.client_secret);
    };

    const handleAddingCard = () => {
        setCardAddingState((prevValue) => !prevValue);
        fetchPaymentIntent();
    };

    const fetchPaymentCards = async () => {
        const { data } = await PaymentService.getPaymentCards();
        setCards(data.cards);
        setDefaultPayment(data.default_payment_method);
    };

    const handleDeleteCard = async (stripeId) => {
        confirm({ description: 'Are you sure that you want to delete this card? ' }).then(async () => {
            try {
                const { data } = await PaymentService.deletePaymentMethod(stripeId);
                enqueueSnackbar('Card deleted!', {
                    variant: 'success',
                    autoHideDuration: 3000
                });
                setCards(cards.filter((card) => card.id !== stripeId));
            } catch (error) {
                console.error('Failed to delete payment method:', error);
            }
        });
    };

    const handleDefaultCardSelection = async (stripeId) => {
        setDefaultPayment(stripeId);

        try {
            const { data } = await PaymentService.setDefaultPaymentMethod(stripeId);

            enqueueSnackbar('Card set as default payment system!', {
                variant: 'success',
                autoHideDuration: 3000
            });
        } catch (error) {
            console.error('Failed to set default payment method:', error);
        }
    };

    useEffect(() => {
        fetchPaymentIntent();
        fetchPaymentCards();
    }, []);

    return (
        <>
            <Typography variant="h3" mt={1}>
                Payment Methods
            </Typography>

            {/* Card adding Part */}
            <Grid container spacing={2}>
                {cardAddingState && clientSecret && (
                    <Grid item xs={12} sm={6} md={6} mt={2} mb={2}>
                        <Elements options={options} stripe={stripePromise}>
                            <AddCardForm
                                clientSecret={clientSecret}
                                setCardAddingState={setCardAddingState}
                                setAddingButtonLoadingState={setAddingButtonLoadingState}
                                setCards={setCards}
                                fetchPaymentIntent={fetchPaymentIntent}
                            />
                        </Elements>

                        <Box display="flex" justifyContent="flex-end" mt={2} gap={2}>
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                type="submit"
                                form="payment-form"
                                loading={addingButtonLoadingState}
                            >
                                Save Card
                            </LoadingButton>
                            <Button variant="outlined" color="secondary" onClick={() => setCardAddingState(false)}>
                                Cancel
                            </Button>
                        </Box>
                    </Grid>
                )}
            </Grid>

            {/* Cards Part */}
            <Grid container spacing={2} mt={2}>
                {cards?.map((card) => (
                    <Grid item xs={12} sm={4} md={2} key={card.id}>
                        <Card
                            sx={{
                                border: defaultPayment === card.id ? '2px solid #1976d2' : '1px solid #e0e0e0',
                                borderRadius: '12px',
                                position: 'relative',
                                cursor: 'pointer',
                                '&:hover': {
                                    boxShadow: '0 4px 8px rgba(0,0,0,0.3)'
                                }
                            }}
                            onClick={() => handleDefaultCardSelection(card.id)}
                        >
                            <Radio
                                checked={defaultPayment === card.id}
                                sx={{
                                    position: 'absolute',
                                    top: 8,
                                    right: 0
                                }}
                            />
                            <CardContent sx={{ pt: 4, pb: 2, px: 2 }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <CardMedia
                                        component="img"
                                        image={card.card.brand === 'visa' ? VisaImage : MastercardImage}
                                        title={card.card.brand}
                                        sx={{ width: '60px', height: 'auto' }}
                                    />
                                    <Typography variant="body1" sx={{ mt: 1 }}>
                                        •••• •••• •••• {card.card.last4}
                                    </Typography>
                                    <Typography variant="caption" color="textSecondary">
                                        Expires {dayjs(`${card.card.exp_year}-${card.card.exp_month}-01`).format('MM/YY')}
                                    </Typography>
                                </Box>
                            </CardContent>
                            <CardActions sx={{ justifyContent: 'flex-end', p: 1 }}>
                                <IconButton
                                    size="small"
                                    sx={{
                                        borderRadius: '100%',
                                        backgroundColor: 'rgba(106, 106, 137, 0.3)',
                                        '&:hover': {
                                            backgroundColor: 'rgba(106, 106, 137, 0.5)'
                                        }
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteCard(card.id);
                                    }}
                                >
                                    <IconMinus color="white" />
                                </IconButton>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
                {/* Card add icon */}
                <Grid item xs={12} sm={4} md={2}>
                    <Card
                        sx={{
                            border: '1px dashed #bdbdbd',
                            borderRadius: '12px',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            backgroundColor: 'rgba(106, 106, 137, 0.05)',
                            '&:hover': {
                                borderColor: '#1976d2',
                                backgroundColor: 'rgba(106, 106, 137, 0.07)'
                            }
                        }}
                        onClick={handleAddingCard}
                    >
                        <CardContent>
                            <Box
                                sx={{
                                    textAlign: 'center',
                                    borderRadius: '100%',
                                    backgroundColor: 'rgba(106, 106, 137, 0.3)',
                                    p: 2,
                                    color: 'white'
                                }}
                            >
                                <IconPlus />
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};
PaymentMethods.propTypes = {
    subscriptions: PropTypes.array
};

export default PaymentMethods;
