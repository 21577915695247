// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, Typography } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';
import { useDispatch, useSelector } from 'store';
import useAuth from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { IconArrowBack, IconArrowLeft, IconPlus } from '@tabler/icons';
import useOnePageSurveyStore from 'zustand_store/useOnePageSurveyStore';
import { useState } from 'react';
import SurveyTypeSelectorModal from 'views/surveys/survey-type-selection';
import useMasterSurveyResponseStore from 'zustand_store/useMasterSurveyResponseStore';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();
    const { user } = useAuth();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const params = new URLSearchParams(window.location.search);
    // Check if 'advance-form-create' is part of the current URL
    const isAdvanceFormCreate = window.location.href.includes('advance-form-create');
    const isSurveyCreate = window.location.href.includes('surveys') && window.location.href.includes('add');
    const isSurveyEdit = window.location.href.includes('surveys') && window.location.href.includes('edit');
    const survey = useOnePageSurveyStore((state) => state.questions[0]?.survey);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { currentSurvey } = useMasterSurveyResponseStore();

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    // width: '200px',
                    padding: '15px 0',
                    display: 'flex',
                    width: '100%',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box
                    component="span"
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        flexGrow: 1,
                        marginLeft: params.get('mode') === 'wordpress' ? '5%' : '0'
                    }}
                >
                    {isSurveyCreate && (
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            <Button onClick={() => navigate(-1)} sx={{ minWidth: 'auto', mr: 2 }}>
                                <IconArrowLeft />
                            </Button>
                            <Box sx={{ flex: 1, textAlign: 'center', width: '100%' }}>
                                <Typography variant="h2" color="primary">
                                    Create New Survey
                                </Typography>
                            </Box>
                        </Box>
                    )}

                    {isSurveyEdit && (
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            <Button onClick={() => navigate(-1)} sx={{ minWidth: 'auto', mr: 2 }}>
                                <IconArrowLeft />
                            </Button>
                            <Box sx={{ flex: 1, textAlign: 'center', width: '100%' }}>
                                <Typography variant="h2" color="primary">
                                    Edit Survey
                                </Typography>
                            </Box>
                        </Box>
                    )}

                    {isAdvanceFormCreate && (
                        <Typography variant="h3" sx={{ paddingTop: '5px' }} color="primary">
                            <Button onClick={() => navigate('/surveys')}>
                                <IconArrowLeft />
                            </Button>
                            {currentSurvey?.survey_title ?? ''}
                        </Typography>
                    )}
                    {!isSurveyCreate && !isAdvanceFormCreate && !isSurveyEdit && (
                        <Button
                            variant="contained"
                            sx={{ color: 'white', boxShadow: 'none' }}
                            color="secondary"
                            onClick={() => setIsModalOpen(true)}
                            startIcon={<IconPlus />}
                        >
                            Create New Survey
                        </Button>
                    )}
                </Box>
            </Box>
            <Box sx={{ flexGrow: 1 }} />

            <ProfileSection />
            <SurveyTypeSelectorModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />
            {/* mobile header */}
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box>
        </>
    );
};

export default Header;
