import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, CardActions, CardContent, Divider, Grid, IconButton, Modal, Alert, Typography, Box, Paper } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import SurveyService from 'services/survey.service';
import useRestrictionStore from 'zustand_store/useRestrictionStore';
import RuleComponent from './RuleComponent';
import ListSkeleton from 'views/ListSkeleton';

const Body = React.forwardRef((props, ref) => {
    const { handleClose, surveyToken, allQuesData, setAllQuesData, survey } = props;

    const [questionLoaded, setQuestionLoaded] = useState(false);
    const [allQuestitle, setAllQuestitle] = useState([]);
    const [choiceMap, setChoiceMap] = useState({});
    const saveButton = useRef(null);

    const restrictionStore = useRestrictionStore((state) => state.ruleEngine);
    const ruleEngine = restrictionStore?.find((element) => Number(Object.keys(element)[0]) === survey.id)
        ? Object.values(restrictionStore?.find((element) => Number(Object.keys(element)[0]) === survey.id))[0]
        : [];

    const { enqueueSnackbar } = useSnackbar();
    const ADD_RULENGINE = useRestrictionStore((state) => state.ADD_RULENGINE);
    const allQtitle = useCallback(() => allQuestitle, [allQuestitle]);
    const [formikInit, setFormikInit] = useState(ruleEngine || []);

    const fetchAllQuestion = async () => {
        const { data } = await SurveyService.showQuestions(surveyToken);
        setAllQuesData(data.data);
        setAllQuestitle(data.data?.map((questionData) => questionData.question_title));
        setQuestionLoaded(true);
    };

    const onRestrictionModalSubmit = async (values) => {
        values.restrictions = values.restrictions?.map((restrictionProperties, index) => {
            const findQuesId = allQuesData[index]?.id;
            const findQuesOrder = allQuesData[index]?.order;
            return { ...restrictionProperties, qID: findQuesId, qOrder: findQuesOrder };
        });

        await SurveyService.postSurveyRestrictions(surveyToken, values.restrictions);
        handleClose();
        ADD_RULENGINE(survey.id, values.restrictions);
        enqueueSnackbar('Logical Restrictions saved successfully', { variant: 'success', autoHideDuration: 3000 });
    };

    useEffect(() => {
        fetchAllQuestion();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (allQuesData.length > 0) {
            allQuesData.forEach((questionData) => {
                const { meta } = questionData;
                setChoiceMap((prevChoice) => ({
                    ...prevChoice,
                    [questionData.id]: meta?.choices
                }));
            });

            if (!Array.isArray(formikInit) || formikInit.length === 0 || survey.meta.rule_engine) {
                const emptyArr = allQtitle().length > 0 ? allQtitle().length - formikInit.length : 0;
                if (emptyArr > 0 && emptyArr < 1000) {
                    setFormikInit((prevState) => [...prevState, ...Array(emptyArr).fill({})]);
                }
            }
        }
    }, [allQuestitle, allQuesData]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Paper ref={ref} elevation={3} sx={{ width: '100%', maxWidth: 1000, maxHeight: '90vh', overflowY: 'auto' }} tabIndex={-1}>
            <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h5">Branching and Calculation</Typography>
                <IconButton onClick={handleClose} size="small">
                    <CloseIcon />
                </IconButton>
            </Box>
            <Divider />
            <CardContent sx={{ bgcolor: 'background.default' }}>
                {!questionLoaded ? (
                    <ListSkeleton number={10} />
                ) : (
                    <Box sx={{ height: '60vh', overflowY: 'auto', pr: 2 }}>
                        {allQuestitle.length === Object.values(formikInit).length &&
                            allQuesData.length === Object.values(formikInit).length && (
                                <Formik
                                    initialValues={{ restrictions: formikInit }}
                                    onSubmit={onRestrictionModalSubmit}
                                    validateOnChange={false}
                                    validateOnBlur
                                    enableReinitialize={false}
                                >
                                    <Form>
                                        <Grid container spacing={2}>
                                            {Object.values(formikInit)?.map((_, index) => (
                                                <React.Fragment key={index}>
                                                    {index > 0 && (
                                                        <Grid item xs={12}>
                                                            <Divider sx={{ my: 2 }} />
                                                        </Grid>
                                                    )}
                                                    <Grid item xs={12}>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            Question {index + 1}
                                                        </Typography>
                                                        {allQuesData && !isEmpty(allQuesData) && allQuesData?.length > 1 && (
                                                            <RuleComponent
                                                                allQuesData={allQuesData}
                                                                allQtitle={allQtitle}
                                                                choiceMap={choiceMap}
                                                                index={index}
                                                                survey={survey}
                                                                data-testid="rule-component"
                                                            />
                                                        )}
                                                    </Grid>
                                                </React.Fragment>
                                            ))}
                                        </Grid>
                                        <Button ref={saveButton} type="submit" sx={{ display: 'none' }}>
                                            Save
                                        </Button>
                                    </Form>
                                </Formik>
                            )}
                        {questionLoaded && allQuesData?.length < 2 && (
                            <Alert severity="error" sx={{ mt: 2 }}>
                                To use the branching and calculation feature, at least two questions are needed
                            </Alert>
                        )}
                    </Box>
                )}
            </CardContent>
            <Divider />
            <CardActions sx={{ justifyContent: 'space-between', p: 2 }}>
                <Button color="error" variant="outlined" onClick={handleClose}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={questionLoaded && allQuesData?.length < 2}
                    startIcon={<SaveIcon />}
                    onClick={() => saveButton.current.click()}
                >
                    Save
                </Button>
            </CardActions>
        </Paper>
    );
});

Body.propTypes = {
    handleClose: PropTypes.func.isRequired,
    surveyToken: PropTypes.string.isRequired,
    allQuesData: PropTypes.array.isRequired,
    setAllQuesData: PropTypes.func.isRequired,
    survey: PropTypes.object.isRequired
};

const SimpleModal = ({ open, ...props }) => (
    <Modal
        open={open}
        aria-labelledby="branching-and-calculation-modal"
        aria-describedby="modal-for-survey-branching-and-calculation"
        sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}
    >
        <Body {...props} />
    </Modal>
);

SimpleModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    surveyToken: PropTypes.string.isRequired,
    allQuesData: PropTypes.array.isRequired,
    setAllQuesData: PropTypes.func.isRequired,
    survey: PropTypes.object.isRequired
};

export default SimpleModal;
