/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Box,
    Grid,
    Typography,
    Card,
    CardContent,
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Divider,
    CircularProgress,
    useTheme,
    useMediaQuery,
    LinearProgress
} from '@mui/material';
import { Dashboard as DashboardIcon, AssignmentTurnedIn, TrendingUp, People, AttachMoney, CloudDownload, Add } from '@mui/icons-material';
import useAuth from 'hooks/useAuth';
import SurveyService from 'services/survey.service';
import MiscService from 'services/misc.service';
import GlobalModalHelpButton from 'views/components/GlobalModalHelpButton';
import { IconChartBar, IconNote, IconServerOff, IconTrophy, IconShare } from '@tabler/icons';
import transformToJqDatatable from 'utils/data-transformers';
import DismissibleAlert from 'views/components/DismissibleAlert';
import ListActionButtons from 'views/surveys/ListActionButtons';
import { useSnackbar } from 'notistack';
import SurveyTypeSelectorModal from 'views/surveys/survey-type-selection';

const DashBoard = () => {
    const [dashboardData, setDashboardData] = useState(null);
    const [recentSurveys, setRecentSurveys] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { user } = useAuth();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [selectedSurveyToken, setSelectedSurveyToken] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const fetchData = useCallback(async () => {
        if (!user) {
            navigate('/login');
            return;
        }

        try {
            const params = transformToJqDatatable({
                columns: [{ field: 'updated_at', sortable: true }],
                pagination: {
                    page: 0,
                    rowsPerPage: 5,
                    sortBy: 'updated_at',
                    direction: 'desc'
                }
            });

            const [dashboardResponse, surveysResponse] = await Promise.all([
                MiscService.getDashboardData(user.id),
                SurveyService.list(params)
            ]);
            setDashboardData(dashboardResponse.data);
            setRecentSurveys(surveysResponse.data.data);
        } catch (error) {
            enqueueSnackbar(error, { variant: 'error', autoHideDuration: 5000 });
        } finally {
            setIsLoading(false);
        }
    }, [user, navigate]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const StatItem = ({ icon: Icon, label, value }) => (
        <Grid item xs={12} md={2}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Icon sx={{ color: 'text.secondary' }} />
                <Box>
                    <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                        {value}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {label}
                    </Typography>
                </Box>
            </Box>
        </Grid>
    );

    StatItem.propTypes = {
        icon: PropTypes.elementType.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    };

    const QuotaBar = ({ quota }) => {
        const percentageFinder = (value) => {
            const [numerator, denominator] = value.split(' / ').map(Number);
            return 100 - (numerator / denominator) * 100;
        };

        const generateColorOnText = (value) => {
            const [numerator, denominator] = value.split(' / ').map(Number);
            return `<span style="color: #f76d6d; max-width: '100px'">${numerator}</span> out of <span style="color: #354154">${denominator}</span>`;
        };
        return (
            <Box sx={{ width: '100%', display: 'block' }}>
                <Box>
                    <Typography
                        align="center"
                        variant="body2"
                        color="primary"
                        dangerouslySetInnerHTML={{ __html: generateColorOnText(quota) }}
                    />
                </Box>
                <LinearProgress
                    variant="determinate"
                    value={percentageFinder(quota)}
                    color="primary"
                    sx={{
                        width: '100%',
                        height: 5,
                        backgroundColor: '#354154',
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: '#e0e0e0'
                        }
                    }}
                />
            </Box>
        );
    };

    QuotaBar.propTypes = {
        quota: PropTypes.string.isRequired
    };

    const QuickAction = ({ icon: Icon, title, description, action }) => (
        <Box sx={{ mb: 2, mt: '16px' }}>
            <Typography variant="subtitle1" component="h3" gutterBottom>
                <Icon sx={{ verticalAlign: 'bottom' }} /> {title}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                {description}
            </Typography>
            {action}
        </Box>
    );

    QuickAction.propTypes = {
        icon: PropTypes.elementType.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        action: PropTypes.node.isRequired
    };

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box>
            <Typography variant="h4" sx={{ mb: 3, fontWeight: 'bold' }}>
                Dashboard Overview
            </Typography>

            <Grid container spacing={4} sx={{ mb: 4 }}>
                <StatItem icon={AssignmentTurnedIn} label="Total Surveys" value={dashboardData.surveys} />
                <StatItem icon={People} label="Total Responses" value={dashboardData.answers} />
                <StatItem icon={AttachMoney} label="Total Spending" value={`$${dashboardData.spending}`} />
                <StatItem icon={TrendingUp} label="Response Rate" value={`${dashboardData.response_rate}%`} />
                <StatItem icon={IconTrophy} label="Response Filled" value={dashboardData.response_filled} />
                <StatItem icon={CloudDownload} label="Responses Remaining" value={dashboardData.response_remained} />
            </Grid>

            <Typography variant="h4" sx={{ mt: 5, fontWeight: 'bold' }}>
                {recentSurveys.length > 0 ? (
                    'Recent Activity'
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minHeight: 'calc(100vh - 300px)',
                            textAlign: 'center',
                            width: '100%',
                            position: 'absolute',
                            left: '50%',
                            top: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}
                    >
                        <IconServerOff size={60} sx={{ color: 'text.primary', mb: 2 }} />
                        <Typography variant="h2" color="text.primary" gutterBottom>
                            No Surveys Available
                        </Typography>
                        <Typography variant="body2" color="text.primary">
                            Create your first survey to get started!
                        </Typography>
                        <Button
                            variant="contained"
                            sx={{
                                mt: 2,
                                color: 'white',
                                boxShadow: 'none'
                            }}
                            color="secondary"
                            startIcon={<Add />}
                            onClick={() => setIsModalOpen(true)}
                        >
                            Create New Survey
                        </Button>
                    </Box>
                )}
            </Typography>

            {recentSurveys.length > 0 && (
                <Card>
                    <Box sx={{ p: 2 }}>
                        <Grid container sx={{ pb: 2, borderBottom: '1px solid #eee' }}>
                            <Grid item xs={10}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                    Survey Title
                                </Typography>
                            </Grid>
                            <Grid item xs={1} sx={{ textAlign: 'center' }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                    Quota Available
                                </Typography>
                            </Grid>
                            <Grid item xs={1} sx={{ textAlign: 'right' }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                    Action
                                </Typography>
                            </Grid>
                        </Grid>

                        {recentSurveys.map((survey, index) => (
                            <Grid
                                container
                                key={survey.survey_token}
                                sx={{
                                    py: 2,
                                    borderBottom: index === recentSurveys.length - 1 ? 'none' : '1px solid #eee'
                                }}
                            >
                                <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <IconNote size={20} />
                                    <Box>
                                        <Typography
                                            variant="body1"
                                            component={RouterLink}
                                            to={`/${survey.survey_token}/advance-form-create?tab=overview`}
                                            sx={{
                                                color: 'text.primary',
                                                textDecoration: 'none',
                                                '&:hover': {
                                                    textDecoration: 'underline'
                                                }
                                            }}
                                        >
                                            {survey.survey_title}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Status: {survey.survey_status}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <QuotaBar quota={survey.quota || '0 / 0'} />
                                </Grid>
                                <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <ListActionButtons
                                        surveyInformation={survey}
                                        surveyToken={survey.survey_token}
                                        onActionComplete={fetchData}
                                    />
                                </Grid>
                            </Grid>
                        ))}
                    </Box>
                </Card>
            )}
            <SurveyTypeSelectorModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </Box>
    );
};

export default DashBoard;
