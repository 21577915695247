import React, { lazy, useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, IconButton, Button, Box, CardContent, Grid } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { IconArrowBack } from '@tabler/icons';
import SurveyService from 'services/survey.service';
import Loadable from 'ui-component/Loadable';
import { ThumbUp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const SurveyPreviewModal = ({ open, onClose, survey }) => {
    const [questions, setQuestions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const renderInitialData = async () => {
        if (!survey?.survey_token) return;
        //  Get Questions with survey information
        const { data } = await SurveyService.getAllQuestion(survey.survey_token);
        setQuestions(data);
        setIsLoading(false);
    };

    const cloneTemplate = async (surveyToken) => {
        const { data } = await SurveyService.cloneTemplate(surveyToken);
        navigate(`/surveys/${data.survey_token}/edit`);
    };

    const currentQuestionChangeHandler = (currentQuestion) => {
        const Component = Loadable(lazy(() => import(`views/single-preview/${currentQuestion?.meta.identifier}`)));
        return <Component questionData={currentQuestion} focus={false} />;
    };

    useEffect(() => {
        renderInitialData();
    }, [survey, survey?.survey_token]); // eslint-disable-line react-hooks/exhaustive-deps

    return survey?.survey_token ? (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="lg"
            fullWidth
            PaperProps={{
                sx: {
                    height: '90vh',
                    maxHeight: '900px'
                }
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '24px', position: 'relative' }}>
                <DialogTitle sx={{ textAlign: 'center', width: '100%' }}>
                    <Typography variant="h3">
                        {survey?.survey_title}{' '}
                        <Button
                            variant="contained"
                            size="small"
                            startIcon={<ThumbUp />}
                            sx={{
                                borderRadius: '8px',
                                textTransform: 'none',
                                boxShadow: 'none'
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onClose();
                                cloneTemplate(survey?.survey_token);
                            }}
                        >
                            Use It
                        </Button>
                    </Typography>
                </DialogTitle>
                <IconButton
                    onClick={onClose}
                    size="large"
                    style={{ padding: '8px 10px', position: 'absolute', right: '30px', borderRadius: '0', top: '30px' }}
                    aria-label="close"
                >
                    <IconArrowBack />
                </IconButton>
            </div>

            <DialogContent>
                <Typography variant="h2" py={5}>
                    {survey?.survey_title}
                </Typography>{' '}
                {questions &&
                    questions?.map((question, index) => (
                        <CardContent sx={{ pb: '16px !important' }} key={index}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        width: '100%',
                                        margin: '0 auto'
                                    }}
                                >
                                    <>{currentQuestionChangeHandler(question)}</>
                                </Grid>
                            </Grid>
                        </CardContent>
                    ))}
            </DialogContent>
        </Dialog>
    ) : null;
};

SurveyPreviewModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    survey: PropTypes.object
};

export default SurveyPreviewModal;
