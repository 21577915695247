import { Tooltip, tooltipClasses } from '@mui/material';
import PropTypes from 'prop-types';

const SurveyflipTooltip = ({ children, ...rest }) => (
    <Tooltip
        {...rest}
        arrow
        componentsProps={{
            popper: {
                sx: {
                    [`& .${tooltipClasses.arrow}`]: {
                        color: (theme) => theme.palette.common.black
                    },
                    [`& .${tooltipClasses.tooltip}`]: {
                        backgroundColor: (theme) => theme.palette.common.black
                    }
                }
            }
        }}
    >
        {children}
    </Tooltip>
);

SurveyflipTooltip.propTypes = {
    children: PropTypes.node.isRequired
};

export default SurveyflipTooltip;
