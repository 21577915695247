import React from 'react';
import PropTypes from 'prop-types';
import { Box, TextField } from '@mui/material';

const ColorPicker = ({ color, onChange }) => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField value={color} size="small" onChange={(e) => onChange(e.target.value)} sx={{ width: '100px', mr: 1 }} />
        <Box
            sx={{
                width: '36px',
                height: '36px',
                borderRadius: '4px',
                backgroundColor: color,
                border: '1px solid #d0d0d0',
                cursor: 'pointer',
                overflow: 'hidden'
            }}
        >
            <input
                type="color"
                value={color}
                onChange={(e) => onChange(e.target.value)}
                style={{
                    width: '150%',
                    height: '150%',
                    transform: 'translate(-25%, -25%)',
                    cursor: 'pointer',
                    opacity: 0
                }}
            />
        </Box>
    </Box>
);

ColorPicker.propTypes = {
    color: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default ColorPicker;
