import React from 'react';
import PropTypes from 'prop-types';

const RequiredLabel = ({ text }) => (
    <span>
        {text} <span style={{ color: 'red' }}>*</span>
    </span>
);

RequiredLabel.propTypes = {
    text: PropTypes.string.isRequired
};

export default RequiredLabel;
