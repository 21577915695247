import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

const BillingHistory = ({ payments }) => (
    <>
        <Typography variant="h3" mt={5} mb={3}>
            Billing History
        </Typography>
        <TableContainer
            component={Paper}
            sx={{
                borderRadius: '10px',
                border: '1px solid #e0e0e0'
            }}
        >
            <Table sx={{ padding: '20px' }}>
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Payment</TableCell>
                        <TableCell>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {payments.map((payment) => (
                        <TableRow key={payment.id}>
                            <TableCell sx={{ padding: '8px 16px!important', height: '36px', lineHeight: '1' }}>
                                {dayjs(payment.created_at).format('MMM DD, YYYY')}
                            </TableCell>
                            <TableCell sx={{ padding: '8px 16px!important', height: '36px', lineHeight: '1' }}>{payment.amount}</TableCell>
                            <TableCell sx={{ padding: '8px 16px!important', height: '36px', lineHeight: '1' }}>
                                <Chip chipcolor="orange" sx={{ padding: '4px 10px!important' }} label={payment.payment_type} size="small" />
                            </TableCell>
                            <TableCell sx={{ padding: '8px 16px!important', height: '36px', lineHeight: '1' }}>
                                <Chip
                                    label={payment.meta?.status ? payment.meta.status : 'Success'}
                                    color={payment.meta?.status ? 'error' : 'success'}
                                    sx={{ padding: '4px 10px!important' }}
                                    size="small"
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </>
);

BillingHistory.propTypes = {
    payments: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            created_at: PropTypes.string.isRequired,
            amount: PropTypes.string.isRequired,
            payment_type: PropTypes.string.isRequired,
            meta: PropTypes.shape({
                status: PropTypes.string
            })
        })
    ).isRequired
};

export default BillingHistory;
