import React from 'react';
import AllowComments from 'views/questions/properties-page/DrawerComponents/AllowComments';
import AllowMultiple from 'views/questions/properties-page/DrawerComponents/AllowMultiple';
import AlphabeticOrder from 'views/questions/properties-page/DrawerComponents/AlphabeticOrder';
import DisplayVertically from 'views/questions/properties-page/DrawerComponents/DisplayVertically';
import IsActive from 'views/questions/properties-page/DrawerComponents/IsActive';
import MaxCharacters from 'views/questions/properties-page/DrawerComponents/MaxCharacters';
import AnswerVariable from 'views/questions/properties-page/DrawerComponents/AnswerVariable';
import Others from 'views/questions/properties-page/DrawerComponents/Others';
import Randomize from 'views/questions/properties-page/DrawerComponents/Randomize';
import Required from 'views/questions/properties-page/DrawerComponents/Required';
import create from 'zustand';
import Category from 'views/questions/properties-page/DrawerComponents/Category';
import UploadImage from 'views/questions/properties-page/DrawerComponents/UploadImage';
import Layout from 'views/questions/properties-page/DrawerComponents/Layout';
import WelcomeScreen from 'views/questions/properties-page/DrawerComponents/WelcomeScreen';
import RequiredColumn from 'views/questions/properties-page/DrawerComponents/RequiredColumn';

import OnePageAllowComments from 'views/questions/one-page-properties-page/DrawerComponents/AllowComments';
import OnePageAllowMultiple from 'views/questions/one-page-properties-page/DrawerComponents/AllowMultiple';
import OnePageAlphabeticOrder from 'views/questions/one-page-properties-page/DrawerComponents/AlphabeticOrder';
import OnePageDisplayVertically from 'views/questions/one-page-properties-page/DrawerComponents/DisplayVertically';
import OnePageIsActive from 'views/questions/one-page-properties-page/DrawerComponents/IsActive';
import OnePageMaxCharacters from 'views/questions/one-page-properties-page/DrawerComponents/MaxCharacters';
import OnePageAnswerVariable from 'views/questions/one-page-properties-page/DrawerComponents/AnswerVariable';
import OnePageOthers from 'views/questions/one-page-properties-page/DrawerComponents/Others';
import OnePageRandomize from 'views/questions/one-page-properties-page/DrawerComponents/Randomize';
import OnePageRequired from 'views/questions/one-page-properties-page/DrawerComponents/Required';
import OnePageCategory from 'views/questions/one-page-properties-page/DrawerComponents/Category';
import OnePageUploadImage from 'views/questions/one-page-properties-page/DrawerComponents/UploadImage';
import OnePageLayout from 'views/questions/one-page-properties-page/DrawerComponents/Layout';
import OnePageWelcomeScreen from 'views/questions/one-page-properties-page/DrawerComponents/WelcomeScreen';
import OnePageRequiredColumn from 'views/questions/one-page-properties-page/DrawerComponents/RequiredColumn';

const useQuestionDrawerStore = create(() => ({
    ruleset: {
        ShortQuestion: ['required', 'isActive', 'maxCharacters', 'answerVariable', 'category'],
        LongQuestion: ['required', 'isActive', 'maxCharacters', 'answerVariable', 'category'],
        DropdownQuestion: ['required', 'isActive', 'alphabeticOrder', 'allowMultiple', 'answerVariable', 'category'],
        BooleanQuestion: ['required', 'isActive', 'category'],
        MatrixQuestion: ['required', 'requiredColumn', 'allowMultiple', 'isActive', 'category'],
        MatrixDropdownQuestion: ['required', 'requiredColumn', 'isActive', 'category'],
        MatrixDynamicQuestion: ['required', 'requiredColumn', 'isActive', 'category'],
        McqQuestion: ['required', 'isActive', 'allowMultiple', 'others', 'answerVariable', 'category'],
        RatingQuestion: ['required', 'isActive', 'answerVariable', 'category'],
        CommentQuestion: ['required', 'isActive', 'allowMultiple', 'allowComments', 'answerVariable', 'category'],
        HtmlQuestion: ['required', 'isActive', 'answerVariable', 'category'],
        ImagePickerQuestion: ['required', 'isActive', 'allowMultiple', 'answerVariable', 'category'],
        PhoneNumberQuestion: ['required', 'isActive', 'maxCharacters', 'answerVariable', 'category'],
        EmailQuestion: ['required', 'isActive', 'answerVariable', 'category'],
        OpinionScaleQuestion: ['required', 'isActive', 'displayVertically', 'answerVariable', 'category'],
        CountryQuestion: ['required', 'isActive', 'answerVariable', 'category'],
        StateQuestion: ['required', 'isActive', 'answerVariable', 'category'],
        MessageQuestion: ['isActive', 'welcomeScreen', 'layout', 'uploadImage'],
        FilesUploadingQuestion: ['isActive', 'required'],
        NameQuestion: ['isActive', 'required'],
        DateQuestion: ['isActive', 'required']
    },
    onePageTemplates: {
        required: (formikState) => <OnePageRequired formik={formikState} />,
        isActive: (formikState) => <OnePageIsActive formik={formikState} />,
        maxCharacters: (formikState) => <OnePageMaxCharacters formik={formikState} />,
        answerVariable: (formikState) => <OnePageAnswerVariable formik={formikState} />,
        randomize: (formikState) => <OnePageRandomize formik={formikState} />,
        alphabeticOrder: (formikState) => <OnePageAlphabeticOrder formik={formikState} />,
        allowMultiple: (formikState) => <OnePageAllowMultiple formik={formikState} />,
        others: (formikState) => <OnePageOthers formik={formikState} />,
        allowComments: (formikState) => <OnePageAllowComments formik={formikState} />,
        displayVertically: (formikState) => <OnePageDisplayVertically formik={formikState} />,
        category: (formikState) => <OnePageCategory formik={formikState} />,
        uploadImage: (formikState) => <OnePageUploadImage formik={formikState} />,
        layout: (formikState) => <OnePageLayout formik={formikState} />,
        welcomeScreen: (formikState) => <OnePageWelcomeScreen formik={formikState} />,
        requiredColumn: (formikState) => <OnePageRequiredColumn formik={formikState} />
    },
    templates: {
        required: (formikState) => <Required formik={formikState} />,
        isActive: (formikState) => <IsActive formik={formikState} />,
        maxCharacters: (formikState) => <MaxCharacters formik={formikState} />,
        answerVariable: (formikState) => <AnswerVariable formik={formikState} />,
        randomize: (formikState) => <Randomize formik={formikState} />,
        alphabeticOrder: (formikState) => <AlphabeticOrder formik={formikState} />,
        allowMultiple: (formikState) => <AllowMultiple formik={formikState} />,
        others: (formikState) => <Others formik={formikState} />,
        allowComments: (formikState) => <AllowComments formik={formikState} />,
        displayVertically: (formikState) => <DisplayVertically formik={formikState} />,
        category: (formikState) => <Category formik={formikState} />,
        // title: (formikState) => <Title formik={formikState} />,
        // subTitle: (formikState) => <SubTitle formik={formikState} />,
        // buttonText: (formikState) => <ButtonText formik={formikState} />,
        uploadImage: (formikState) => <UploadImage formik={formikState} />,
        layout: (formikState) => <Layout formik={formikState} />,
        welcomeScreen: (formikState) => <WelcomeScreen formik={formikState} />,
        requiredColumn: (formikState) => <RequiredColumn formik={formikState} />
    },
    surveyRestrictions: {
        category: ['Assessment']
    }
}));

export default useQuestionDrawerStore;
