import { TextField, Tooltip } from '@mui/material';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Emitter from 'utils/emitter';

const RequiredColumn = ({ formik }) => {
    // Default Value
    const [requiredColumn, setRequiredColumn] = useState(1);
    const [requiredCurrentState, setRequiredCurrentState] = useState(true);
    const [allowMultipleCurrentState, setAllowMultipleCurrentState] = useState(true);
    const [colCount, setColCount] = useState(1);
    const [error, setError] = useState('');

    const valueSetter = (formik) => {
        // Only for Matrix (Checkbox) Question
        if (formik?.values?.meta.identifier === 'MatrixQuestion' || typeof formik?.values?.meta?.allow_multiple !== 'undefined') {
            setAllowMultipleCurrentState(formik?.values?.meta?.allow_multiple);
        }
        setRequiredCurrentState(formik?.values?.meta?.required);

        if (formik && formik.values && isUndefined(formik.values?.meta?.required_column)) {
            // add
            formik.setFieldValue('meta.required_column', requiredColumn);
        } else if (!isUndefined(formik.values?.meta?.required_column)) {
            // edit
            setRequiredColumn(formik.values.meta.required_column);
        }
    };

    Emitter.on('FORMIK_RENDERED', (formik) => valueSetter(formik));
    Emitter.on('TABLE_COL_CHANGED', (colCount) => setColCount(colCount));

    const handleNumberChange = (event) => {
        const value = Number(event.target.value);
        const totalCols = formik?.values?.meta?.tableData?.cols?.length || 0;

        if (formik?.values?.meta?.required && (value < 1 || value > totalCols)) {
            setError(`Required columns must be between 1 and ${totalCols}`);
        } else {
            setError('');
        }

        formik.setFieldValue('meta.required_column', value);
    };

    useEffect(() => {
        if (!requiredCurrentState && !formik?.values?.meta.required_column) {
            setRequiredColumn(0);
            formik.setFieldValue('meta.required_column', 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requiredCurrentState]);

    useEffect(() => {
        if (!allowMultipleCurrentState && !formik?.values?.meta.required_column) {
            setRequiredColumn(0);
            formik.setFieldValue('meta.required_column', 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allowMultipleCurrentState]);

    return (
        <>
            {(requiredCurrentState && allowMultipleCurrentState) ||
            (formik?.values?.question_type?.identifier === 'matrix_question' && requiredCurrentState) ||
            (formik?.values?.question_type?.identifier === 'matrix_dropdown_question' && requiredCurrentState) ||
            (formik?.values?.question_type?.identifier === 'matrix_dynamic_question' && requiredCurrentState) ? (
                <Tooltip title="Specify the number of columns that respondents must answer when this question is required">
                    <TextField
                        onChange={handleNumberChange}
                        name="meta.required_column"
                        value={requiredColumn}
                        id="standard-basic"
                        fullWidth
                        m={1}
                        placeholder="Required Column"
                        variant="outlined"
                        type="number"
                        disabled={!requiredCurrentState}
                        label="Required Column"
                        error={!!formik?.errors?.meta?.required_column || !!error}
                        helperText={formik?.errors?.meta?.required_column || error}
                    />
                </Tooltip>
            ) : null}
        </>
    );
};
RequiredColumn.propTypes = {
    formik: PropTypes.object
};
export default RequiredColumn;
