/* eslint-disable react/self-closing-comp */
import { Box, Button, Grid, Tab, Tabs, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import { Icon3dRotate, IconDashboard, IconPlus, IconReport, IconShare } from '@tabler/icons';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import CreateAdvanceSurvey from './create';
import ChangePassword from 'views/account/profile/ChangePassword';
import Settings from 'views/account/profile/Settings';
import Membership from 'views/account/profile/Subscription';
import Integration from './integration';
import SurveyService from 'services/survey.service';
import Share from './share';
import Report from '../report';
import { LoadingButton } from '@mui/lab';
import Item from './Item';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import Overview from './overview';
import { useDispatch } from 'react-redux';
import { activeMainItem } from 'store/slices/menu';
import useMasterSurveyResponseStore from 'zustand_store/useMasterSurveyResponseStore';
import * as yup from 'yup';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import DateTimeFields from '../DateTimeFields';
import MiscService from 'services/misc.service';

dayjs.extend(utc);
dayjs.extend(timezone);

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box
                    sx={{
                        p: 0
                    }}
                >
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const tabsOption = [
    {
        label: 'Overview',
        icon: <IconDashboard sx={{ fontSize: '1.3rem' }} />,
        url: '?tab=overview'
    },
    {
        label: 'Create',
        icon: <IconPlus sx={{ fontSize: '1.3rem' }} />,
        url: '?tab=create'
    },
    {
        label: 'Integration',
        icon: <Icon3dRotate sx={{ fontSize: '1.3rem' }} />,
        url: '?tab=integration'
    },
    {
        label: 'Share',
        icon: <IconShare sx={{ fontSize: '1.3rem' }} />,
        url: '?tab=share'
    },
    {
        label: 'Report',
        icon: <IconReport sx={{ fontSize: '1.3rem' }} />,
        url: '?tab=report'
    }
];

const Builder = () => {
    const [value, setValue] = useState(0);
    const location = useLocation();
    const [survey, setSurvey] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const { surveyToken } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { SET_CURRENT_SURVEY } = useMasterSurveyResponseStore();
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedTimezone, setSelectedTimezone] = useState(dayjs.tz.guess());
    const [isOpen, setIsOpen] = useState(false);
    const [endOpen, setEndOpen] = useState(false);
    const [timezones, setTimezones] = useState([]);

    const getTimezones = async () => {
        const { data } = await MiscService.getTimezones();
        setTimezones(data.timezones);
    };

    const handlePublish = async (startDate, endDate) => {
        setIsLoading(true);
        let values = {};
        if (survey.survey_status === 'Development') {
            values = {
                ...survey,
                survey_status: 'Live',
                start_date: startDate,
                end_date: endDate
            };
        } else {
            values = { ...survey, survey_status: 'Development' };
        }
        const { data: surveyData } = await SurveyService.update(survey.survey_token, values);
        SET_CURRENT_SURVEY(surveyData);

        if (surveyData.survey_status === 'Development') {
            setValue(0);
            enqueueSnackbar('Survey unpublished', { variant: 'success', autoHideDuration: 2500 });
        } else {
            setValue(3);
            enqueueSnackbar('Survey Published', { variant: 'success', autoHideDuration: 2500 });
        }
        setSurvey(surveyData);
        setIsLoading(false);

        // window.location.href = `${window.location.href}`;
    };

    const validationSchema = yup.object({
        start_date: yup
            .date()
            .transform((v) => (v instanceof Date && !Number.isNaN(v) ? v : null))
            .required('Start Date field is Required')
            .typeError('Start Date field is Required and must be in date format'),
        // .min(
        //     new Date(
        //         dayjs(new Date().toLocaleString('en-US', { timeZone: selectedTimezone }))
        //             .tz(selectedTimezone)
        //             .valueOf()
        //     ),
        //     'Start date cannot precede the current time.'
        // ),
        end_date: yup
            .date()
            .transform((v) => (v instanceof Date && !Number.isNaN(v) ? v : null))
            .required('End Date field is Required')
            .when('start_date', (startDate, schema) => {
                if (startDate) {
                    const endDate = new Date(startDate).getTime();
                    return schema.min(new Date(endDate), 'End date has to be after than start date');
                }
                return schema;
            })
            .typeError('End Date field is Required and must be in date format'),
        meta: yup.object({
            timezone: yup.string().required('Timezone is required')
        })
    });

    const formik = useFormik({
        initialValues: {
            start_date: new Date(),
            end_date: dayjs().add(7, 'days').toDate(),
            meta: { timezone: selectedTimezone }
        },
        validationSchema,
        onSubmit: async (values) => {
            const startDateInUtc = dayjs.tz(dayjs(values.start_date).format('YYYY-MM-DD HH:mm:ss'), values.meta.timezone).utc().format();
            const endDateInUtc = dayjs.tz(dayjs(values.end_date).format('YYYY-MM-DD HH:mm:ss'), values.meta.timezone).utc().format();

            // Include these dates in your publish logic
            await handlePublish(startDateInUtc, endDateInUtc);
            setOpenDialog(false);
        }
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const fetchSurveyinfo = async () => {
        const { data } = await SurveyService.show(surveyToken);
        SET_CURRENT_SURVEY(data);
        setSurvey(data);
    };

    const handlePreview = () => {
        window.open(`/#/preview/${survey?.meta?.short_code}`, '_blank', 'noreferrer');
    };

    const handleTab = () => {
        // Create a URLSearchParams object with the search string from the location object
        const queryParams = new URLSearchParams(location.search);

        // Get the value of the 'tab' parameter
        const tabValue = queryParams.get('tab');

        if (tabValue === 'overview') {
            setValue(0);
        }

        if (tabValue === 'integration') {
            setValue(2);
        }

        if (tabValue === 'share') {
            setValue(3);
        }

        if (tabValue === 'report') {
            setValue(4);
        }
    };

    const handlePublishClick = () => {
        setOpenDialog(true);
    };

    useEffect(() => {
        handleTab();
        fetchSurveyinfo();
        getTimezones();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (survey.survey_status === 'Live') {
            const queryParams = new URLSearchParams(location.search);
            // Get the value of the 'tab' parameter
            const tabValue = queryParams.get('tab');
            if (tabValue !== 'integration' && tabValue !== 'overview' && tabValue !== 'share' && tabValue !== 'report') {
                // window.location.href = `${window.location.href}?tab=integration`;
                // Go to Share tab
                setValue(3);
            }
        }
        if (survey.survey_status !== 'Live') {
            const queryParams = new URLSearchParams(location.search);
            const tabValue = queryParams.get('tab');

            if (tabValue === 'create') {
                setValue(1);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [survey]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    aria-label="Menu Options"
                    // variant="fullWidth"
                    className="advance-form-top-menu"
                    style={{ position: 'fixed', zIndex: '1101', width: 'calc(100% - 370px)', top: '0', left: '250px' }}
                >
                    {tabsOption?.map((tab, index) => (
                        <Tab
                            iconPosition="top"
                            key={index}
                            component={Link}
                            to={`${tab.url}`}
                            icon={tab.icon}
                            label={tab.label}
                            {...a11yProps(index)}
                            disabled={survey?.survey_status === 'Live' && tab.label === 'Create'}
                        />
                    ))}
                </Tabs>

                <Item
                    sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        position: 'absolute',
                        zIndex: '1101',
                        top: '10px',
                        right: '120px'
                    }}
                >
                    <Button variant="outlined" sx={{ marginRight: '10px' }} onClick={handlePreview}>
                        Preview
                    </Button>
                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        onClick={survey.survey_status === 'Development' ? handlePublishClick : handlePublish}
                        disabled={survey?.quota === 0}
                    >
                        {survey.survey_status === 'Development' ? 'Publish' : 'Unpublish'}
                    </LoadingButton>
                </Item>

                <TabPanel value={value} index={0}>
                    {!isEmpty(survey) && <Overview survey={survey} setValue={setValue} setSurvey={setSurvey} />}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {!isEmpty(survey) && <CreateAdvanceSurvey survey={survey} setSurvey={setSurvey} />}
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Integration />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Share survey={survey} />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <Report />
                </TabPanel>
            </Grid>

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="md" fullWidth>
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle sx={{ lineHeight: '0.5' }}>
                        <Typography variant="h2">Set Survey Schedule</Typography>
                        <Typography variant="caption" color="#555">
                            You are one step away from publishing your survey
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            <DateTimeFields
                                formik={formik}
                                timezones={timezones}
                                selectedTimezone={selectedTimezone}
                                setSelectedTimezone={setSelectedTimezone}
                                mode="add"
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                endOpen={endOpen}
                                setEndOpen={setEndOpen}
                            />
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
                        <LoadingButton type="submit" variant="contained" loading={isLoading}>
                            Publish
                        </LoadingButton>
                    </DialogActions>
                </form>
            </Dialog>
        </Grid>
    );
};

export default Builder;
