import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Alert, AlertTitle, Grid, Skeleton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import paymentService from 'services/payment.service';
import MainCard from 'ui-component/cards/MainCard';
import { useSnackbar } from 'notistack';
// assets
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import dayjs from 'dayjs';
import { IconCrown } from '@tabler/icons';

const ActivePackages = ({ stripeStatus }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [packages, setPackages] = useState([]);
    const [fetched, setFetched] = useState(false);
    const [subscriptionData, setSubscriptionData] = useState(null);
    const [isUpgradeButtonLoading, setIsUpgradeButtonLoading] = useState(false);
    const [clickedPlan, setClickedPlan] = useState('');
    const [subscriptionDetails, setSubscriptionDetails] = useState(null);

    const getPackages = async () => {
        const { data } = await paymentService.getPackages();
        setFetched(true);
        setPackages(data);
    };

    const getSubscription = async () => {
        const { data: paymentData } = await paymentService.getSubscriptions();
        setSubscriptionDetails(paymentData.subscriptions);
    };

    const { enqueueSnackbar } = useSnackbar();

    const upgradePackage = async (priceId) => {
        setClickedPlan(priceId);
        setIsUpgradeButtonLoading(true);
        if (stripeStatus === 'none' || stripeStatus === 'canceled' || stripeStatus === 'incomplete' || stripeStatus === 'past_due') {
            const { data: sessionData } = await paymentService.createCheckoutSession({ price_id: priceId });
            setIsUpgradeButtonLoading(false);

            window.location.href = sessionData.url;
            // Previous Experiment code ends here
        } else {
            const { data } = await paymentService.swapCustomerPlan({ price_id: priceId, stripe_status: stripeStatus });
            setIsUpgradeButtonLoading(false);

            setSubscriptionDetails(data.subscription);
            enqueueSnackbar('Subscription Changed', { variant: 'success', autoHideDuration: 5000 });

            // navigate('/profile/handle-membership');
        }

        /**
         * Subscribe the user with the price_id here
         * and move to payment page
         */
        // const {
        //     data: { clientSecret, subscriptionId, redirectTo }
        // } = await paymentService.subscribe({ price_id: priceId });
        // setSubscriptionData({ subscriptionId, clientSecret, redirectTo });
    };

    // useEffect(() => {
    //     if (subscriptionData) {
    //         enqueueSnackbar('Subscription Changed', { variant: 'success', autoHideDuration: 2500 });
    //         navigate(
    //             {
    //                 pathname: subscriptionData.redirectTo
    //             },
    //             { state: subscriptionData }
    //         );
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [subscriptionData, navigate]);

    useEffect(() => {
        getPackages();
        getSubscription();
    }, []);

    const priceListDisable = {
        opacity: '0.4',
        '& >div> svg': {
            fill: theme.palette.secondary.light
        }
    };

    return (
        <>
            <Grid container spacing={2}>
                {subscriptionDetails && subscriptionDetails.length === 0 && (
                    <Grid item xs={12}>
                        <Alert severity="error" icon={false}>
                            <AlertTitle>Important</AlertTitle>
                            You currently do not have any active membership.
                        </Alert>
                    </Grid>
                )}
                {fetched && subscriptionDetails ? (
                    packages.map((plan, index) => {
                        const isPlanActive = subscriptionDetails.price_id === plan.id;
                        return (
                            <Grid item xs={12} sm={6} lg={3} key={index}>
                                <MainCard
                                    sx={{
                                        position: 'relative',
                                        backgroundColor: isPlanActive ? 'rgba(233, 30, 99, 0.04)' : '#f8f7fc',
                                        border: '1px solid',
                                        borderColor: isPlanActive ? 'rgba(81,123,195,255)' : 'divider',
                                        overflow: 'visible',
                                        borderRadius: '5px',
                                        height: '100%'
                                    }}
                                >
                                    {isPlanActive && (
                                        <CheckTwoToneIcon
                                            sx={{
                                                position: 'absolute',
                                                top: '-8px',
                                                right: '-8px',
                                                zIndex: 1000000,
                                                backgroundColor: 'rgba(62,109,214,255)',
                                                color: 'white',
                                                borderRadius: '50%',
                                                padding: '3px'
                                            }}
                                        />
                                    )}
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={6}>
                                            <Typography
                                                variant="h3"
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    fontSize: { xs: '1.2rem', sm: '1.5rem' }
                                                }}
                                            >
                                                {plan.product.name}
                                                {(plan.product.name.toLowerCase().includes('premium') ||
                                                    plan.product.name.toLowerCase().includes('pro')) && <IconCrown sx={{ ml: 1 }} />}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography
                                                variant="h4"
                                                color="primary"
                                                sx={{
                                                    textAlign: { xs: 'left', sm: 'right' },
                                                    fontSize: { xs: '1.1rem', sm: '1.3rem' }
                                                }}
                                            >
                                                ${plan.unit_amount}
                                                <Typography component="span" variant="body2" color="textSecondary">
                                                    /month
                                                </Typography>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} mb={1}>
                                            <Typography variant="body2" color="#616161">
                                                {plan.responses} responses per month
                                            </Typography>
                                        </Grid>
                                        {subscriptionDetails.price_id !== plan.id && (
                                            <Grid item xs={12}>
                                                <LoadingButton
                                                    variant="text"
                                                    disabled={isPlanActive}
                                                    sx={{
                                                        color: 'rgba(81,123,195,255)',
                                                        padding: 0,
                                                        '&:hover': { backgroundColor: 'transparent', textDecoration: 'underline' }
                                                    }}
                                                    onClick={() => upgradePackage(plan.id)}
                                                    loading={clickedPlan === plan.id && isUpgradeButtonLoading}
                                                >
                                                    Click to Update
                                                </LoadingButton>
                                            </Grid>
                                        )}
                                        {subscriptionDetails.price_id === plan.id && (
                                            <Grid item xs={12}>
                                                <Typography variant="body2" color="#616161">
                                                    Next charge will be on{' '}
                                                    {dayjs(subscriptionDetails.current_period_end * 1000).format('MMM DD, YYYY')}
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </MainCard>
                            </Grid>
                        );
                    })
                ) : (
                    <>
                        {[1, 2, 3, 4].map((item) => (
                            <Grid item xs={12} sm={6} lg={3} key={item}>
                                <Skeleton variant="rounded" height={200} width="100%" />
                            </Grid>
                        ))}
                    </>
                )}
            </Grid>
        </>
    );
};

ActivePackages.propTypes = {
    stripeStatus: PropTypes.string
};
export default ActivePackages;
