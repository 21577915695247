import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import AuthGuard from 'utils/route-guard/AuthGuard';
import Loadable from 'ui-component/Loadable';
import MyAccount from 'views/account/profile/MyAccount';
import ChangePassword from 'views/account/profile/ChangePassword';
import Settings from 'views/account/profile/Settings';
import DashBoard from 'views/dashboard';
import Upgrade from 'views/account/upgrade';
import SubscriptionComplete from 'views/account/SubscriptionComplete';
import Subscription from 'views/account/profile/Subscription';
import HandleMembership from 'views/account/profile/HandleMembership';
import GoogleSheetLoginHandler from 'views/GoogleSheetLoginHandler';

// sample page routing

const SurveyList = Loadable(lazy(() => import('views/surveys/list')));
const Packages = Loadable(lazy(() => import('views/account/packages')));
const AddSurvey = Loadable(lazy(() => import('views/surveys/add')));
const EditSurvey = Loadable(lazy(() => import('views/surveys/edit')));
const ViewSurvey = Loadable(lazy(() => import('views/surveys/view')));
const AdvanceSurveyCreate = Loadable(lazy(() => import('views/surveys/advance-form/create')));
const AddQuestion = Loadable(lazy(() => import('views/questions/add')));
const EditQuestion = Loadable(lazy(() => import('views/questions/edit')));
const Report = Loadable(lazy(() => import('views/surveys/report')));
const SampleCard = Loadable(lazy(() => import('views/sample-page')));
const MyRequests = Loadable(lazy(() => import('views/surveys/myRequests')));
const TemplateCard = Loadable(lazy(() => import('views/dashboard/TemplateCard')));
const TemplateView = Loadable(lazy(() => import('views/dashboard/TemplateView')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '',
            children: [
                {
                    index: true,
                    path: '',
                    element: <DashBoard />
                }
            ]
        },
        {
            path: 'api/enable-sheet/google/callback',
            element: <GoogleSheetLoginHandler />
        },
        {
            path: 'upgrade',
            children: [
                {
                    index: true,
                    path: '',
                    element: <Upgrade />
                },
                {
                    path: 'subscription-complete',
                    element: <SubscriptionComplete />
                }
            ]
        },
        {
            path: 'profile',
            children: [
                {
                    index: true,
                    path: '',
                    element: <MyAccount />
                },
                {
                    path: 'change-password',
                    element: <ChangePassword />
                },
                {
                    path: 'settings',
                    element: <Settings />
                },
                {
                    path: 'subscription',
                    element: <Subscription />
                },
                {
                    path: 'handle-membership',
                    element: <HandleMembership />
                }
            ]
        },
        {
            path: 'packages',
            element: <Packages />
        },
        {
            path: 'earning',
            element: <SampleCard />
        },
        {
            path: 'payment',
            element: <SampleCard />
        },
        {
            path: 'surveys',
            children: [
                {
                    index: true,
                    // path: '',
                    element: <SurveyList />
                },
                {
                    path: 'add',
                    element: <AddSurvey />
                },
                {
                    path: 'templates',
                    element: <TemplateCard />
                },
                {
                    path: 'templates/:surveyToken/view',
                    element: <TemplateView />
                },
                {
                    path: ':surveyToken/edit',
                    element: <EditSurvey />
                },
                {
                    path: ':surveyToken/view',
                    element: <ViewSurvey />
                },
                {
                    path: ':surveyToken/report',
                    element: <Report />
                },
                {
                    path: ':surveyToken/add-question',
                    element: <AddQuestion />
                },
                {
                    path: ':surveyToken/edit-question/:questionId',
                    element: <EditQuestion />
                }
            ]
        },
        {
            path: 'my-requests',
            children: [
                {
                    path: '',
                    element: <MyRequests />
                }
            ]
        }
    ]
};

export default MainRoutes;
