import React from 'react';
import PropTypes from 'prop-types';
import { Grid, MenuItem, Stack, TextField } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

const DateTimeFields = ({ formik, timezones, selectedTimezone, setSelectedTimezone, mode, isOpen, setIsOpen, endOpen, setEndOpen }) => (
    <>
        <Grid item xs={12} md={4}>
            <TextField
                label="Select Timezone"
                name="meta.timezone"
                onChange={(event) => {
                    setSelectedTimezone(event.target.value);
                }}
                value={formik.values?.meta?.timezone}
                error={formik.touched?.meta?.timezone && Boolean(formik.errors?.meta?.timezone)}
                helperText={formik.touched?.meta?.timezone && formik.errors?.meta?.timezone}
                fullWidth
                select
                focused={mode === 'edit'}
            >
                {timezones.map((timezone) => (
                    <MenuItem key={timezone} value={timezone}>
                        {timezone}
                    </MenuItem>
                ))}
            </TextField>
        </Grid>
        <Grid item xs={12} md={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3}>
                    <DateTimePicker
                        open={isOpen}
                        onOpen={() => setIsOpen(true)}
                        onClose={() => setIsOpen(false)}
                        type="text"
                        minDateTime={new Date()}
                        renderInput={(props) => (
                            <TextField
                                name="start_date"
                                type="text"
                                fullWidth
                                onClick={() => setIsOpen(true)}
                                {...props}
                                error={formik.touched.start_date && Boolean(formik.errors.start_date)}
                                helperText={formik.touched.start_date && formik.errors.start_date}
                            />
                        )}
                        label="Start Date"
                        value={formik.values.start_date}
                        onChange={(value) => {
                            formik.setFieldValue('start_date', value);
                        }}
                    />
                </Stack>
            </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3}>
                    <DateTimePicker
                        open={endOpen}
                        onOpen={() => setEndOpen(true)}
                        onClose={() => setEndOpen(false)}
                        type="text"
                        minDateTime={new Date()}
                        renderInput={(props) => (
                            <TextField
                                name="end_date"
                                type="text"
                                fullWidth
                                onClick={() => setEndOpen(true)}
                                {...props}
                                error={formik.touched.end_date && Boolean(formik.errors.end_date)}
                                helperText={formik.touched.end_date && formik.errors.end_date}
                            />
                        )}
                        label="End Date"
                        value={formik.values.end_date}
                        onChange={(value) => {
                            formik.setFieldValue('end_date', value);
                        }}
                    />
                </Stack>
            </LocalizationProvider>
        </Grid>
    </>
);

DateTimeFields.propTypes = {
    formik: PropTypes.object.isRequired,
    timezones: PropTypes.array.isRequired,
    selectedTimezone: PropTypes.string.isRequired,
    setSelectedTimezone: PropTypes.func.isRequired,
    mode: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    endOpen: PropTypes.bool.isRequired,
    setEndOpen: PropTypes.func.isRequired
};

export default DateTimeFields;
