import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Alert, AlertTitle, Button, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import paymentService from 'services/payment.service';
import MainCard from 'ui-component/cards/MainCard';
import { useSnackbar } from 'notistack';
// assets
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import PropTypes from 'prop-types';

// project imports

const planList = [
    'One End Product',
    'No attribution required',
    'TypeScript',
    'Figma Design Resources',
    'Create Multiple Products',
    'Create a SaaS Project',
    'Resale Product',
    'Separate sale of our UI Elements?'
];

const TrialPackages = ({ stripeStatus }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [packages, setPackages] = useState([]);
    const [fetched, setFetched] = useState(false);
    const [subscriptionData, setSubscriptionData] = useState(null);
    const [prevSubscriptions, setprevSubscriptions] = useState(null);

    const getPackages = async () => {
        const { data } = await paymentService.getAllPackages();
        const {
            data: { subscriptions }
        } = await paymentService.getSubscriptions();

        setprevSubscriptions(subscriptions);

        setFetched(true);
        setPackages(data);
    };
    const { enqueueSnackbar } = useSnackbar();

    const upgradePackage = async (priceId) => {
        const { data: sessionData } = await paymentService.createCheckoutSession({ price_id: priceId });
        window.location.href = sessionData.url;
        // First cancel previous Trial Subscription
        // await paymentService.cancelSubscription({ id: prevSubscriptions.id });

        /**
         * Subscribe the user with the price_id here
         * and move to payment page
         */
        // const {
        //     data: { clientSecret, subscriptionId, redirectTo }
        // } = await paymentService.cancelTrialAndSubscribe({ price_id: priceId });
        // setSubscriptionData({ subscriptionId, clientSecret, redirectTo });
    };

    useEffect(() => {
        if (subscriptionData) {
            enqueueSnackbar('Subscription Changed', { variant: 'success', autoHideDuration: 2500 });
            navigate(
                {
                    pathname: subscriptionData.redirectTo
                },
                { state: subscriptionData }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscriptionData, navigate]);

    useEffect(() => {
        getPackages();
    }, []);

    const priceListDisable = {
        opacity: '0.4',
        '& >div> svg': {
            fill: theme.palette.secondary.light
        }
    };

    return (
        <>
            <Grid container direction="row" justifyContent="space-evenly">
                <Grid item>
                    <Grid justifyContent="center" container spacing="20">
                        {fetched && packages.length ? (
                            packages.map((plan, index) => {
                                const darkBorder = '#EEEEEE';
                                return (
                                    <Grid item key={index}>
                                        <MainCard
                                            boxShadow
                                            sx={{
                                                maxWidth: 400,
                                                pt: 1.75,
                                                border: plan.active ? '2px solid' : '1px solid',
                                                borderColor: plan.active ? 'secondary.main' : darkBorder
                                            }}
                                        >
                                            <Grid container textAlign="center" spacing="20">
                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant="h6"
                                                        sx={{
                                                            fontSize: '1.5625rem',
                                                            fontWeight: 500,
                                                            position: 'relative',
                                                            mb: 1.875,
                                                            '&:after': {
                                                                content: '""',
                                                                position: 'absolute',
                                                                bottom: -15,
                                                                left: 'calc(50% - 25px)',
                                                                width: 50,
                                                                height: 4,
                                                                background: theme.palette.primary.main,
                                                                borderRadius: '3px'
                                                            }
                                                        }}
                                                    >
                                                        {plan.product.name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="body2">{plan.description}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        component="div"
                                                        variant="body2"
                                                        sx={{
                                                            fontSize: '2.1875rem',
                                                            fontWeight: 700,
                                                            '& > span': {
                                                                fontSize: '1.25rem',
                                                                fontWeight: 500
                                                            }
                                                        }}
                                                    >
                                                        ${plan.unit_amount / 100}
                                                        <span>/mo</span>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <List
                                                        sx={{
                                                            m: 0,
                                                            p: 0,
                                                            '&> li': {
                                                                px: 0,
                                                                py: 0.625,
                                                                '& svg': {
                                                                    fill: theme.palette.success.dark
                                                                }
                                                            }
                                                        }}
                                                        component="ul"
                                                    >
                                                        {planList.map((list, i) => (
                                                            <React.Fragment key={i}>
                                                                {plan.product?.metadata.features && (
                                                                    <ListItem
                                                                        sx={
                                                                            JSON.parse(plan.product?.metadata.features).includes(list)
                                                                                ? {}
                                                                                : priceListDisable
                                                                        }
                                                                    >
                                                                        <ListItemIcon>
                                                                            <CheckTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                                                                        </ListItemIcon>
                                                                        <ListItemText primary={list} />
                                                                    </ListItem>
                                                                )}
                                                                <Divider />
                                                            </React.Fragment>
                                                        ))}
                                                    </List>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button variant="outlined" onClick={() => upgradePackage(plan.id)}>
                                                        Upgrade
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </MainCard>
                                    </Grid>
                                );
                            })
                        ) : (
                            <>
                                {fetched && !packages.length && (
                                    <Grid item xs={12}>
                                        <Alert severity="error" variant="filled" icon={false}>
                                            <AlertTitle>Error</AlertTitle>
                                            No packages found
                                        </Alert>
                                    </Grid>
                                )}
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

TrialPackages.propTypes = {
    stripeStatus: PropTypes.string
};
export default TrialPackages;
