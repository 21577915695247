import React from 'react';
import PropTypes from 'prop-types';
import { styled, alpha } from '@mui/material/styles';
import { ListItemIcon, Button, MenuItem, Menu, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IconEye, IconChevronDown, IconPencil, IconCopy, IconTrash, IconReport, IconLiveView, IconDots, IconEdit } from '@tabler/icons';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { useConfirm } from 'material-ui-confirm';
import SurveyService from 'services/survey.service';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0'
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5)
            },
            '&:active': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
            }
        }
    }
}));

const ListActionButtons = ({ surveyToken, surveyInformation, makeSurveyLive, onActionComplete }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { user } = useAuth();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const confirm = useConfirm();

    const [open, setOpen] = React.useState(Boolean(anchorEl));
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    const handleDelete = async () => {
        try {
            await confirm({
                description: 'Are you sure that you want to delete this Survey?'
            });

            await SurveyService.delete(surveyToken);
            enqueueSnackbar('Survey deleted successfully', {
                variant: 'success',
                autoHideDuration: 3000
            });

            // Notify parent component to refresh data
            if (onActionComplete) {
                onActionComplete();
            }
        } catch (error) {
            if (error !== undefined) {
                // User didn't cancel
                console.error('Error deleting survey:', error);
                enqueueSnackbar('Error deleting survey', {
                    variant: 'error',
                    autoHideDuration: 3000
                });
            }
        }
    };

    const handleDuplicate = async () => {
        try {
            const { data: newSurvey } = await SurveyService.duplicate(surveyToken);
            enqueueSnackbar('Survey duplicated successfully. You will be redirecting in edit mode for this duplicate survey...', {
                variant: 'success',
                autoHideDuration: 3000
            });

            setTimeout(() => navigate(`/surveys/${newSurvey?.survey?.survey_token}/edit`), 3000);

            // Notify parent component to refresh data
            if (onActionComplete) {
                onActionComplete();
            }
        } catch (error) {
            console.error('Error duplicating survey:', error);
            enqueueSnackbar('Error duplicating survey', {
                variant: 'error',
                autoHideDuration: 3000
            });
        }
    };

    return (
        <>
            <IconButton
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                size="small"
                disableRipple
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <IconDots stroke={1} size="1.5rem" />
            </IconButton>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button'
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={() => {
                        handleClose();
                        navigate(`/${surveyToken}/advance-form-create?tab=overview`);
                    }}
                    disableRipple
                >
                    <ListItemIcon>
                        <IconEye />
                    </ListItemIcon>
                    View
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        navigate(`/surveys/${surveyToken}/edit`);
                    }}
                    disableRipple
                >
                    <ListItemIcon>
                        <IconEdit />
                    </ListItemIcon>
                    Edit
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        handleClose();
                        handleDuplicate();
                    }}
                    disableRipple
                >
                    <ListItemIcon>
                        <IconCopy />
                    </ListItemIcon>
                    Duplicate
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        handleDelete();
                    }}
                    disableRipple
                >
                    <ListItemIcon>
                        <IconTrash />
                    </ListItemIcon>
                    Delete
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        navigate(`/${surveyToken}/advance-form-create?tab=report`);
                    }}
                    disableRipple
                >
                    <ListItemIcon>
                        <IconReport />
                    </ListItemIcon>
                    Report
                </MenuItem>

                {user?.user_role?.includes('Super Admin') &&
                    surveyInformation &&
                    surveyInformation.survey_prize > 0 &&
                    surveyInformation.survey_status === 'Development' && (
                        <MenuItem onClick={() => makeSurveyLive(surveyInformation.survey_token, surveyInformation)} disableRipple>
                            <ListItemIcon>
                                <IconLiveView />
                            </ListItemIcon>
                            Survey Publish
                        </MenuItem>
                    )}
            </StyledMenu>
        </>
    );
};
ListActionButtons.propTypes = {
    surveyToken: PropTypes.string,
    surveyInformation: PropTypes.object,
    makeSurveyLive: PropTypes.func,
    onActionComplete: PropTypes.func
};
export default ListActionButtons;
