// material-ui
import { useEffect, useState } from 'react';
import {
    Alert,
    AlertTitle,
    Button,
    CardActions,
    Grid,
    Chip,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import paymentService from 'services/payment.service';
import dayjs from 'dayjs';
import Pusher from 'pusher-js';
import useAuth from 'hooks/useAuth';
import BillCard from 'ui-component/cards/BillCard';
import SubCard from 'ui-component/cards/SubCard';
import { useNavigate } from 'react-router-dom';

const HandleMembership = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const pusher = new Pusher('971dc76faa89074b989b', {
        cluster: 'ap2',
        encrypted: true
    });

    const handleNewNotification = async (data) => {
        if (data.charging_status) {
            navigate('/profile/subscription', {
                state: {
                    message: 'You have successfully changed your subscription!'
                }
            });
        }

        // Handle the new notification data
        // getSubscription();
    };

    useEffect(() => {
        const channel = pusher.subscribe('stripe-us');
        channel.bind('App\\Events\\SubscriptionPackageUpdate', handleNewNotification);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(() => {
    //     getSubscription();
    // }, [subscriptionUpdated]);

    return (
        <>
            <Grid container direction="column" spacing={3}>
                <Grid item alignContent="left" xs={12}>
                    <Typography variant="h3" pt={1} mb={3}>
                        We are working on your subscription. Please wait for a while....
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

export default HandleMembership;
