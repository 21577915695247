import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
    Alert,
    AlertTitle,
    Avatar,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    LinearProgress,
    MenuItem,
    Paper,
    TextField,
    Typography
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Save, Upload, Close as CloseIcon, ShoppingCart } from '@mui/icons-material';
import GeneralSettingsService from 'services/profile.service';
import GeneralService from 'services/general.service';
import { useSnackbar } from 'notistack';
import useStore from 'zustand_store/useStore';
import useAuth from 'hooks/useAuth';
import Slider from '@mui/material/Slider';
import { Box } from '@mui/system';
import BuyMoreModal from './BuyMoreModal';
import { useNavigate } from 'react-router-dom';
import AccountResponseInformation from './AccountResponseInformation';

const validationSchema = yup.object({
    first_name: yup.string().required('First name is required'),
    last_name: yup.string().required('Last Name is required'),
    email: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
    gender: yup.string().required('Gender is required'),
    date_of_birth: yup
        .date()
        .transform((v) => (v instanceof Date && !Number.isNaN(v) ? v : null))
        .required('Date of Birth field is Required')
        .typeError('Date of Birth field is Required and must be in date format')
});

const MyAccount = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);
    const [loaderOpen, setLoaderOpen] = useState(false);
    const { logout, user } = useAuth();
    const navigate = useNavigate();

    const [gender, setGender] = React.useState([
        {
            label: 'Male',
            value: 'm'
        },
        {
            label: 'Female',
            value: 'f'
        }
    ]);

    const [generalSetting, setGeneralSetting] = React.useState({
        first_name: '',
        last_name: '',
        email: '',
        gender: '',
        date_of_birth: '',
        subscribed: false
    });

    const fetchData = async () => {
        const { data } = await GeneralSettingsService.get();
        setGeneralSetting(data);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const formik = useFormik({
        initialValues: generalSetting,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async (values) => {
            values.subscribed = values?.subscribed?.subscribed ? 1 : 0;
            const response = await GeneralSettingsService.update(values);
            enqueueSnackbar('Profile Updated Successfully!', { variant: 'success', autoHideDuration: 3000 });
            useStore.setState({ userEditDrawerOpen: false });
        }
    });

    const handleUploadClick = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        if (file.size / 1024 / 1024 > 5) {
            enqueueSnackbar('Image must be less than 5MB !', { variant: 'error', autoHideDuration: 3000 });
        } else {
            setLoaderOpen(true);
            reader.onloadend = async () => {
                const fd = new FormData();
                fd.append('image', file);

                const { data: imageLink } = await GeneralService.storeImage(fd, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                formik.setFieldValue('meta.logo', `${imageLink}`);
                setLoaderOpen(false);
            };

            reader.readAsDataURL(file);
        }
    };

    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={8}>
                <Grid item xs={12} md={5}>
                    <Card>
                        <CardContent>
                            <Typography variant="h4" gutterBottom>
                                My Account
                            </Typography>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                Configure information and preferences
                            </Typography>

                            <Box sx={{ mt: 3 }}>
                                <form onSubmit={formik.handleSubmit}>
                                    <TextField
                                        fullWidth
                                        id="first_name"
                                        name="first_name"
                                        label="First Name"
                                        value={formik.values.first_name}
                                        onChange={formik.handleChange}
                                        error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                        helperText={formik.touched.first_name && formik.errors.first_name}
                                        sx={{ mb: 2 }}
                                    />
                                    <TextField
                                        fullWidth
                                        id="last_name"
                                        name="last_name"
                                        label="Last Name"
                                        value={formik.values.last_name}
                                        onChange={formik.handleChange}
                                        error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                                        helperText={formik.touched.last_name && formik.errors.last_name}
                                        sx={{ mb: 2 }}
                                    />
                                    <TextField
                                        fullWidth
                                        id="email"
                                        name="email"
                                        label="Email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                        disabled
                                        sx={{ mb: 3 }}
                                    />
                                    <Button type="submit" variant="contained" startIcon={<Save />}>
                                        Save Changes
                                    </Button>
                                </form>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
};

export default MyAccount;
