import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Box, Typography, LinearProgress, IconButton, Tooltip } from '@mui/material';
import { Upload, DeleteOutline } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import GeneralService from 'services/general.service';

const CoverImageUpload = ({ formik }) => {
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleUploadClick = (event) => {
        const file = event.target.files[0];

        if (file.size / 1024 / 1024 > 5) {
            enqueueSnackbar('Image must be less than 5MB !', { variant: 'error', autoHideDuration: 3000 });
            return;
        }

        // Immediately show the image using local URL
        const localImageUrl = URL.createObjectURL(file);
        formik.setFieldValue('meta.template_cover_image', localImageUrl);

        // Start upload in background
        setIsUploading(true);
        setUploadProgress(0);

        const fd = new FormData();
        fd.append('image', file);

        GeneralService.storeImage(fd, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
                const progress = (progressEvent.loaded / progressEvent.total) * 100;
                setUploadProgress(Math.round(progress));
            }
        })
            .then(({ data: imageLink }) => {
                // Update with actual server URL once upload is complete
                formik.setFieldValue('meta.template_cover_image', imageLink);
                enqueueSnackbar('Image uploaded successfully!', { variant: 'success' });
            })
            .catch((error) => {
                enqueueSnackbar('Failed to upload image. Please try again.', { variant: 'error' });
                formik.setFieldValue('meta.template_cover_image', '');
            })
            .finally(() => {
                setIsUploading(false);
                URL.revokeObjectURL(localImageUrl); // Clean up the local URL
            });
    };

    const handleRemoveImage = () => {
        formik.setFieldValue('meta.template_cover_image', '');
        enqueueSnackbar('Image removed successfully!', { variant: 'success' });
    };

    return (
        <Grid
            style={{
                alignItem: 'center',
                height: '75%',
                width: '100%'
            }}
        >
            <Tooltip title="This will be your Image preview in Social Media">
                <Button startIcon={<Upload />} variant="outlined" component="label" fullWidth disabled={isUploading}>
                    Upload a cover image for Survey
                    <input accept="image/*" id="upload" type="file" onChange={(event) => handleUploadClick(event)} hidden />
                </Button>
            </Tooltip>

            {isUploading && (
                <Box sx={{ width: '100%', mt: 1 }}>
                    <LinearProgress variant="determinate" value={uploadProgress} sx={{ height: 4 }} />
                    <Typography variant="caption" color="textSecondary" display="block" textAlign="center">
                        Uploading: {uploadProgress}%
                    </Typography>
                </Box>
            )}

            {formik?.values?.meta?.template_cover_image && (
                <Grid
                    style={{
                        margin: '20px',
                        height: '200px',
                        position: 'relative'
                    }}
                >
                    <IconButton
                        onClick={handleRemoveImage}
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.9)'
                            }
                        }}
                    >
                        <DeleteOutline />
                    </IconButton>
                    <Box
                        sx={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            overflow: 'hidden'
                        }}
                    >
                        <img
                            src={formik?.values?.meta?.template_cover_image}
                            style={{
                                display: 'block',
                                maxWidth: '100%',
                                maxHeight: '100%',
                                minHeight: '1px'
                            }}
                            alt="images"
                        />
                    </Box>
                </Grid>
            )}
        </Grid>
    );
};

CoverImageUpload.propTypes = {
    formik: PropTypes.object.isRequired
};

export default CoverImageUpload;
