import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography, TextField, Grid, Paper, LinearProgress, IconButton, Tooltip } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SurveyService from 'services/survey.service';
import GeneralService from 'services/general.service';
import { Upload, DeleteOutline } from '@mui/icons-material';
import Item from '../Item';
import { useSnackbar } from 'notistack';
import ColorPicker from './ColorPicker';

const QuestionThemes = ({ survey, setSurvey }) => {
    const [customTheme, setCustomTheme] = useState({
        backgroundColor: '#ffffff',
        questionColor: '#000000',
        answerColor: '#000000',
        buttonColor: '#3f51b5',
        buttonTextColor: '#ffffff',
        backgroundImage: ''
    });
    const [loading, setLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const parseColors = (colorsData) => {
        if (typeof colorsData === 'string') {
            try {
                return JSON.parse(colorsData);
            } catch (error) {
                console.error('Error parsing colors:', error);
                return {};
            }
        }
        return colorsData || {};
    };

    useEffect(() => {
        if (survey.meta?.design) {
            const colors = parseColors(survey.meta.design);
            setCustomTheme({
                backgroundColor: colors.backgroundColor || '#ffffff',
                questionColor: colors.questionColor || '#000000',
                answerColor: colors.answerColor || '#000000',
                buttonColor: colors.buttonColor || '#3f51b5',
                buttonTextColor: colors.buttonTextColor || '#ffffff',
                backgroundImage: colors.backgroundImage || ''
            });
        }
    }, [survey.meta]);

    const handleColorChange = (colorType, value) => {
        setCustomTheme({
            ...customTheme,
            [colorType]: value
        });
    };

    // Helper function to save theme with specific data
    const saveThemeWithData = async (themeData) => {
        try {
            setLoading(true);
            const { data } = await SurveyService.updateSurveyTheme(survey, survey.survey_token, themeData);
            setSurvey({ ...survey, ...data });
        } catch (error) {
            console.error('Error updating survey theme:', error);
            enqueueSnackbar('Failed to update theme', {
                variant: 'error',
                autoHideDuration: 3000
            });
        } finally {
            setLoading(false);
        }
    };

    const handleUploadClick = (event) => {
        const file = event.target.files[0];

        if (file.size / 1024 / 1024 > 5) {
            enqueueSnackbar('Image must be less than 5MB !', { variant: 'error', autoHideDuration: 3000 });
            return;
        }

        // Immediately show the image using local URL
        const localImageUrl = URL.createObjectURL(file);
        setCustomTheme({
            ...customTheme,
            backgroundImage: localImageUrl
        });

        // Start upload in background
        setIsUploading(true);
        setUploadProgress(0);

        const fd = new FormData();
        fd.append('image', file);

        GeneralService.storeImage(fd, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
                const progress = (progressEvent.loaded / progressEvent.total) * 100;
                setUploadProgress(Math.round(progress));
            }
        })
            .then(({ data: imageLink }) => {
                // Update with actual server URL once upload is complete
                const updatedTheme = {
                    ...customTheme,
                    backgroundImage: imageLink
                };
                setCustomTheme(updatedTheme);

                // Automatically save the design after successful upload
                saveThemeWithData(updatedTheme);

                enqueueSnackbar('Background image uploaded and design saved!', { variant: 'success' });
            })
            .catch((error) => {
                console.error('Error uploading image:', error);
                setCustomTheme({
                    ...customTheme,
                    backgroundImage: ''
                });
                enqueueSnackbar('Failed to upload image. Please try again.', { variant: 'error' });
            })
            .finally(() => {
                setIsUploading(false);
                URL.revokeObjectURL(localImageUrl); // Clean up the local URL
            });
    };

    const handleRemoveImage = () => {
        const updatedTheme = {
            ...customTheme,
            backgroundImage: ''
        };
        setCustomTheme(updatedTheme);

        // Automatically save the design after removing image
        saveThemeWithData(updatedTheme);

        enqueueSnackbar('Background image removed and design saved!', { variant: 'success' });
    };

    // Original save function remains for the save button
    const saveCustomTheme = async () => {
        try {
            setLoading(true);
            const { data } = await SurveyService.updateSurveyTheme(survey, survey.survey_token, customTheme);
            setSurvey({ ...survey, ...data });

            enqueueSnackbar('Survey Theme Updated!', {
                variant: 'success',
                autoHideDuration: 3000
            });
        } catch (error) {
            console.error('Error updating survey theme:', error);
            enqueueSnackbar('Failed to update theme', {
                variant: 'error',
                autoHideDuration: 3000
            });
        } finally {
            setLoading(false);
        }
    };

    const openThemeGallery = async () => {
        console.log('Open Theme Gallery clicked');
    };

    return (
        <Item style={{ border: '0' }}>
            <Typography variant="h5" align="left" gutterBottom>
                Design
            </Typography>

            {/* <Button
                variant="outlined"
                fullWidth
                sx={{
                    my: 2,
                    py: 1.5,
                    backgroundColor: '#f0f0f4',
                    borderColor: '#d0d0d0',
                    color: '#000000',
                    '&:hover': {
                        backgroundColor: '#e0e0e4',
                        borderColor: '#c0c0c0'
                    }
                }}
                onClick={openThemeGallery}
            >
                Open Theme Gallery
            </Button> */}

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                        <Typography variant="subtitle1">Background</Typography>
                        <ColorPicker
                            color={customTheme.backgroundColor}
                            onChange={(color) => handleColorChange('backgroundColor', color)}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                        <Typography variant="subtitle1">Questions</Typography>
                        <ColorPicker color={customTheme.questionColor} onChange={(color) => handleColorChange('questionColor', color)} />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                        <Typography variant="subtitle1">Answers</Typography>
                        <ColorPicker color={customTheme.answerColor} onChange={(color) => handleColorChange('answerColor', color)} />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                        <Typography variant="subtitle1">Buttons</Typography>
                        <ColorPicker color={customTheme.buttonColor} onChange={(color) => handleColorChange('buttonColor', color)} />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                        <Typography variant="subtitle1">Button Text</Typography>
                        <ColorPicker
                            color={customTheme.buttonTextColor}
                            onChange={(color) => handleColorChange('buttonTextColor', color)}
                        />
                    </Box>
                </Grid>
            </Grid>

            <Box sx={{ mt: 3 }}>
                <LoadingButton
                    loading={loading}
                    variant="contained"
                    fullWidth
                    onClick={saveCustomTheme}
                    sx={{
                        backgroundColor: customTheme.buttonColor,
                        color: customTheme.buttonTextColor,
                        '&:hover': {
                            backgroundColor: customTheme.buttonColor,
                            opacity: 0.9
                        }
                    }}
                >
                    Save Design
                </LoadingButton>
            </Box>

            <Grid item xs={12} sx={{ mt: 4 }}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    Background Image
                </Typography>
                <Tooltip title="Upload a background image for your survey">
                    <Button startIcon={<Upload />} variant="outlined" component="label" fullWidth disabled={isUploading} sx={{ mb: 2 }}>
                        Upload Background Image
                        <input accept="image/*" type="file" onChange={(event) => handleUploadClick(event)} hidden />
                    </Button>
                </Tooltip>

                {isUploading && (
                    <Box sx={{ width: '100%', mt: 1, mb: 2 }}>
                        <LinearProgress variant="determinate" value={uploadProgress} sx={{ height: 4 }} />
                        <Typography variant="caption" color="textSecondary" display="block" textAlign="center">
                            Uploading: {uploadProgress}%
                        </Typography>
                    </Box>
                )}

                {customTheme.backgroundImage && (
                    <Grid
                        style={{
                            margin: '10px 0 20px',
                            height: '150px',
                            position: 'relative'
                        }}
                    >
                        <IconButton
                            onClick={handleRemoveImage}
                            sx={{
                                position: 'absolute',
                                right: 0,
                                top: 0,
                                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.9)'
                                },
                                zIndex: 1
                            }}
                        >
                            <DeleteOutline />
                        </IconButton>
                        <Box
                            sx={{
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                overflow: 'hidden',
                                border: '1px solid #e0e0e0',
                                borderRadius: '4px'
                            }}
                        >
                            <img
                                src={customTheme.backgroundImage}
                                style={{
                                    display: 'block',
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                    minHeight: '1px'
                                }}
                                alt="Background"
                            />
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Item>
    );
};

QuestionThemes.propTypes = {
    survey: PropTypes.shape({
        survey_token: PropTypes.string.isRequired,
        meta: PropTypes.shape({
            design: PropTypes.array
        })
    }).isRequired,
    setSurvey: PropTypes.func.isRequired
};

export default QuestionThemes;
