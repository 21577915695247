import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Button, Box, Chip, Checkbox } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { animated } from '@react-spring/web';

const RestrictionSection = ({
    addRestriction,
    handleAddRestriction,
    style,
    availableActiveUser,
    availableActiveUserOneMonthAgo,
    availableActiveUserTwoWeekAgo,
    restrictions,
    coreRestrictions,
    selectedRestrictionRef,
    testChange,
    disabled
}) => {
    if (!addRestriction) {
        return (
            <Grid item xs={12} md={6}>
                <Button
                    disabled={disabled}
                    sx={{ textTransform: 'none' }}
                    startIcon={<Add />}
                    variant="outlined"
                    onClick={handleAddRestriction}
                    fullWidth
                >
                    Add Restriction(s)
                </Button>
            </Grid>
        );
    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (disabled) {
            selectedRestrictionRef.current = [];
            handleAddRestriction();
        }
    }, [disabled, handleAddRestriction, selectedRestrictionRef]);

    return (
        <Grid item xs={12} md={12}>
            <Box display="flex" justifyContent="flex-end" mb={2}>
                <Button
                    sx={{ textTransform: 'none' }}
                    startIcon={<Delete />}
                    variant="outlined"
                    onClick={handleAddRestriction}
                    color="error"
                >
                    Remove Restrictions
                </Button>
            </Box>
            <Typography variant="h4" component="h4" gutterBottom xs={{ marginBottom: 2 }}>
                <animated.div style={{ ...style }}>Members:</animated.div>
            </Typography>

            <Grid container alignItems="stretch" justifyContent="space-between" spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="body2" xs={{ marginBottom: '20px', lineHeight: '1.334em' }}>
                        Total Active Respondents: {availableActiveUser || 0}
                    </Typography>
                    <Typography variant="body2" xs={{ marginBottom: '20px', lineHeight: '1.334em' }}>
                        Total Active Within 1 Month: {availableActiveUserOneMonthAgo || 0}
                    </Typography>
                    <Typography variant="body2" xs={{ marginBottom: '20px', lineHeight: '1.334em' }}>
                        Total Active Within 2 weeks: {availableActiveUserTwoWeekAgo || 0}
                    </Typography>
                </Grid>
            </Grid>

            <Typography variant="h4" component="h4" gutterBottom mt={2}>
                Restrictions:
            </Typography>

            <Grid container alignItems="stretch" justifyContent="space-between" spacing={1}>
                {restrictions.length > 0 &&
                    restrictions.map((value, index) => {
                        if (!coreRestrictions.includes(value.key)) return null;

                        const selectedValues = selectedRestrictionRef.current.find((val) => val.key === value.key)?.values ?? [];

                        const selectedChoices = new Set(selectedValues.map((val) => val.choice));

                        return (
                            <Grid item xs={4} key={value.key || index}>
                                <FormControl sx={{ my: 1, width: '100%' }}>
                                    <InputLabel id={`restriction-label-${value.key}`}>{value.label}</InputLabel>
                                    <Select
                                        labelId={`restriction-label-${value.key}`}
                                        id={`restriction-select-${value.key}`}
                                        multiple
                                        fullWidth
                                        value={selectedValues}
                                        onChange={(event) => testChange(event, value.values)}
                                        name={value.key}
                                        input={<OutlinedInput label={value.label} />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value?.id || value?.choice} label={value.choice} />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        {value?.values?.map((choice) => (
                                            <MenuItem key={choice.id || choice.choice} value={choice}>
                                                <Checkbox checked={selectedChoices.has(choice.choice)} />
                                                {choice.choice}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        );
                    })}
            </Grid>
        </Grid>
    );
};

RestrictionSection.propTypes = {
    addRestriction: PropTypes.bool.isRequired,
    handleAddRestriction: PropTypes.func.isRequired,
    style: PropTypes.object.isRequired,
    availableActiveUser: PropTypes.number,
    availableActiveUserOneMonthAgo: PropTypes.number,
    availableActiveUserTwoWeekAgo: PropTypes.number,
    restrictions: PropTypes.array.isRequired,
    coreRestrictions: PropTypes.array.isRequired,
    selectedRestrictionRef: PropTypes.object.isRequired,
    testChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

export default RestrictionSection;
