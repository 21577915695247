/* eslint-disable react/self-closing-comp */
import { Button, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import SurveyService from 'services/survey.service';
import useOnePageSurveyStore from 'zustand_store/useOnePageSurveyStore';

import { IconCopy } from '@tabler/icons';
import Tooltip from '@mui/material/Tooltip';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import QuestionService from 'services/question.service';

// ==============================|| Main Template ||============================== //

const Duplicate = ({ showIcon, showTitle, sx }) => {
    const { enqueueSnackbar } = useSnackbar();

    const UPDATE_QUESTION = useOnePageSurveyStore((state) => state.UPDATE_QUESTION);
    const MUTATE_QUESTIONS_ORDER = useOnePageSurveyStore((state) => state.MUTATE_QUESTIONS_ORDER);
    const ADD_QUESTION = useOnePageSurveyStore((state) => state.ADD_QUESTION);

    const SET_CURRENT_QUESTION = useOnePageSurveyStore((state) => state.SET_CURRENT_QUESTION);
    const currentQuestion = useOnePageSurveyStore((state) => state.currentQuestion);
    const questions = useOnePageSurveyStore((state) => state.questions);
    const { surveyToken } = useParams();

    const handleDuplicateQuestion = async () => {
        const newQuestionId = uuidv4();
        const newQuestion = {
            ...currentQuestion,
            id: newQuestionId,
            question_title: `Copy of ${currentQuestion.question_title}`,
            order: currentQuestion?.order ?? 0 + 1
        };
        ADD_QUESTION(newQuestion);

        const orderChangeQuestions = questions
            ?.filter((question) => question?.order > currentQuestion.order)
            ?.map((question) => ({ id: question?.id, order: question?.order + 1 }));

        MUTATE_QUESTIONS_ORDER(orderChangeQuestions);

        const { data: response } = await SurveyService.duplicateQuestion(currentQuestion?.id, surveyToken, currentQuestion.order + 1);
        console.log('Resource for Duplicating Question', response);
        const createdOrUpdatedQuestionId = newQuestion?.id;
        SET_CURRENT_QUESTION(response.new_question);
        UPDATE_QUESTION(createdOrUpdatedQuestionId, response.new_question);

        enqueueSnackbar('Question Duplicated!', { variant: 'success', autoHideDuration: 2500 });

        const { data } = await QuestionService.massOrderUpdate(surveyToken, { questions: orderChangeQuestions });
    };

    return (
        <>
            {showIcon && (
                <Tooltip title="Duplicate Qustion" placement="top">
                    <span>
                        <Button disabled={typeof currentQuestion?.id !== 'number'} onClick={() => handleDuplicateQuestion()}>
                            <IconCopy />
                        </Button>
                    </span>
                </Tooltip>
            )}
            {showTitle && (
                <Typography
                    variant="h6"
                    disabled={typeof currentQuestion?.id !== 'number'}
                    onClick={() => handleDuplicateQuestion()}
                    sx={sx}
                >
                    Duplicate
                </Typography>
            )}
        </>
    );
};

Duplicate.propTypes = {
    showIcon: PropTypes.bool,
    showTitle: PropTypes.bool,
    sx: PropTypes.object
};

export default Duplicate;
