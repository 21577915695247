import { findIndex, first, last, find } from 'lodash';
import AssignmentOperatorComparer from 'utils/AssignmentOperatorComparer';
import { addOrReplaceObjectPropertyInResponse, compareGenerator } from 'utils/helpers';
import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

const initialState = {
    user_id: '',
    survey_short_code: '',
    currentSurvey: {},
    questions: [],
    currentQuestion: {},
    responses: [],
    currentRuleEngine: [],
    responseTracker: [],
    responseContainer: {
        responses: []
    }
};

const masterSurveyResponseStore = (set, get) => ({
    // State Variables
    ...initialState,
    // Example Of mutation
    STATE_SETTER: (newState) => {
        set((state) => {
            state.user_id = newState.user_id;
            state.survey_short_code = newState.survey_short_code;
            state.questions = newState.questions;
            state.currentQuestion = newState.currentQuestion;
            state.responses = newState.responses;
            state.currentRuleEngine = newState.currentRuleEngine;
            state.responseTracker = newState.responseTracker;
            state.responseContainer = newState.responseContainer;
        });
    },
    SET_USERID_SURVEYTOKEN: (userId, shortCode) => {
        set((state) => {
            state.user_id = userId;
            state.survey_short_code = shortCode;
        });
    },
    SET_QUESTONS: async (questions) => {
        set((state) => {
            state.questions = questions;
        });
    },
    SET_CURRENT_QUESTON: (question) => {
        set((state) => {
            state.currentQuestion = question;
        });
    },
    SET_CURRENT_SURVEY: (survey) => {
        set((state) => {
            state.currentSurvey = survey;
        });
    },
    ADD_RESPONSE: (questionId, answer, answeredAt) => {
        set((state) => {
            addOrReplaceObjectPropertyInResponse({ state, questionId, answer, answeredAt });
        });
    },
    SET_RULE_ENGINE: (ruleEngine) => {
        set((state) => {
            state.currentRuleEngine = ruleEngine;
        });
    },
    TRACK_RESPONSES: (questionId, nextQuestion) => {
        set((state) => {
            if (!state.responseTracker.includes(questionId)) {
                state.responseTracker.push(questionId);
            } else {
                const questionIndex = findIndex(get().responseTracker, (x) => x === questionId);
                const nextQuestionID = get().responseTracker[questionIndex + 1];
                if (nextQuestionID && nextQuestionID !== nextQuestion.id) {
                    state.responseTracker[questionIndex + 1] = nextQuestion.id;
                    state.responseTracker.splice(questionIndex + 1, state.responseTracker.length);
                }
            }
        });
    },
    RESET_STORE_STATE: async () => {
        set(
            (state) => ({ ...initialState }) // Zustand 'replace mode' overwrites existing state
            // true
        );
    },
    RESET_RESPONSES: async () => {
        set((state) => {
            state.responseTracker = [];
            state.responseContainer = {
                responses: []
            };
        });
    },
    // Example of action/getter
    getNextQuestion: (currentQuestionID) => {
        // If there is no Branching and calculation stuff
        // Just go with the flow
        if (get().currentRuleEngine.length === 0) {
            const currentQuestionIndex = findIndex(get().questions, {
                id: currentQuestionID
            });

            if (currentQuestionIndex === 0 || currentQuestionIndex) {
                // Make sure that backend sends sorted question items by order
                return get().questions[currentQuestionIndex + 1];
            }
        } else {
            //  All things that need to be done if there is Rule Engine (Branching/calculation)
            // Find Current Question Rule Engine
            const currentQuestionRuleEngine = find(get().currentRuleEngine, { qID: currentQuestionID });
            const assignmentOperatorComparer = new AssignmentOperatorComparer();
            const currentQuestion = find(get().questions, {
                id: currentQuestionID
            });

            // If there is Rule in a question then iterate through it
            if (currentQuestionRuleEngine?.allRules?.length > 0) {
                const conditionPassedAnswerArray = [];

                //  You get full set set of rules from your rule engine
                currentQuestionRuleEngine?.allRules.map((rule) => {
                    // Then map through the rules
                    rule.cond.map((condition, index) => {
                        // get the question Answer of respondent
                        const conditionQuestionResponse = find(get().responseContainer?.responses, { qID: condition.ques });
                        // get the index of question *Answer* of respondent
                        const conditionQuestionResponseIndex = findIndex(get().responseContainer?.responses, { qID: condition.ques });

                        // If any Condition matches in the rule then store it on array
                        if (
                            compareGenerator(
                                assignmentOperatorComparer,
                                condition,
                                rule,
                                get().responseContainer,
                                conditionQuestionResponse,
                                conditionQuestionResponseIndex,
                                get().questions,
                                index
                            )
                        ) {
                            conditionPassedAnswerArray.push(rule.event.opt);
                        }

                        return false;
                    });
                    return false;
                });

                // If any question answer passed condition then go to that conditioned question
                // else go to always Jump to Question
                if (conditionPassedAnswerArray.length > 0) {
                    return find(get().questions, { id: conditionPassedAnswerArray[0] });
                    // eslint-disable-next-line no-else-return
                } else {
                    return find(get().questions, { id: currentQuestionRuleEngine?.alwaysJumpTo.qID });
                }
            }
            if (currentQuestionRuleEngine?.alwaysJumpTo?.qID) {
                return find(get().questions, { id: currentQuestionRuleEngine?.alwaysJumpTo.qID });
            }
            return find(get().questions, { order: currentQuestion?.order ?? 0 + 1 });
        }
        return false;
    },
    getPrevQuestion: (currentQuestionID) => {
        const currentQuestionIndexInTracker = findIndex(get().responseTracker, (x) => x === currentQuestionID);

        if (get().responseTracker.includes(currentQuestionID)) {
            const prevQuestionID = get().responseTracker[currentQuestionIndexInTracker - 1];
            const prevQuestion = find(get().questions, {
                id: prevQuestionID
            });
            return prevQuestion;
            // eslint-disable-next-line no-else-return
        } else {
            const prevQuestionID = get().responseTracker[get().responseTracker.length - 1];
            const prevQuestion = find(get().questions, {
                id: prevQuestionID
            });
            return prevQuestion;
        }
    },
    // hasPreviousQuestion: (currentQuestion) => {
    //     const firstQuestion = first(get().questions);
    //     if (firstQuestion.id === currentQuestion.id) {
    //         return false;
    //     }
    //     return true;
    // },
    hasPreviousQuestion: (currentQuestion) => {
        const filteredQuestions = get().questions.filter((question) => !question?.meta?.welcome_screen);
        const firstQuestion = first(filteredQuestions);
        if (firstQuestion?.id === currentQuestion?.id) {
            return false;
        }
        return true;
    },
    isLastQuestion: (currentQuestion) => {
        const lastQuestion = last(get().questions);
        if (lastQuestion.id === currentQuestion.id) {
            return true;
        }
        return false;
    }
});

const useMasterSurveyResponseStore = create(
    devtools(
        persist(masterSurveyResponseStore, {
            name: 'responses'
        })
    )
);

export default useMasterSurveyResponseStore;
