/* eslint-disable react/self-closing-comp */
import { Button, Grid, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { IconCopy, IconDeviceFloppy } from '@tabler/icons';
import Tooltip from '@mui/material/Tooltip';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import QuestionService from 'services/question.service';
import { LoadingButton } from '@mui/lab';
import { useState, useRef, useEffect } from 'react';
import { isEqual, cloneDeep } from 'lodash';

// ==============================|| Main Template ||============================== //

const SaveQuestion = ({ formik, formikValues }) => {
    const [isLoading, setIsLoading] = useState(false);
    const initialValues = useRef(cloneDeep(formikValues)); // Using lodash cloneDeep

    const handleSave = async () => {
        setIsLoading(true);
        try {
            await formik.submitForm(); // Using submitForm() instead of handleSubmit()
        } finally {
            setIsLoading(false);
        }
    };

    const handleAutoSave = () => {
        // Create copies without updated_at for comparison
        const initialValuesCopy = cloneDeep(initialValues.current);
        const currentValuesCopy = cloneDeep(formik.values);

        // Remove updated_at from both objects
        delete initialValuesCopy.updated_at;
        delete currentValuesCopy.updated_at;

        // Compare values excluding updated_at
        const isDifferent = !isEqual(initialValuesCopy, currentValuesCopy);

        if (isDifferent && typeof formik.values.id !== 'string' && formik.values.question_title !== '') {
            formik.setFieldValue('extra_info.autosave', true);
            handleSave();
            initialValues.current = cloneDeep(formik.values);
        }
    };

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            handleAutoSave();
        }, 1500);
        return () => clearTimeout(debounceTimer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values]);

    return (
        <Tooltip title="Save Qustion" placement="top">
            <LoadingButton loading={isLoading} onClick={handleSave}>
                <IconDeviceFloppy />
            </LoadingButton>
        </Tooltip>
    );
};

SaveQuestion.propTypes = {
    formik: PropTypes.any,
    formikValues: PropTypes.any
};

export default SaveQuestion;
